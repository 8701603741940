import React from "react";
import { useDispatch } from "react-redux";
import Input from "../../component/Common/Input";
import { useState } from "react";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import {
  createResImg,
  getResImg,
} from "../../store/slice/restaurantImagesSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const ImageModal = ({
  addModal,
  setAddModal,
  imgErr,
  setImgErr,
  uploadLoading,
  setUploadLoading,
  pageNumber,
}) => {
  const showHideClassName = addModal && "user_detail_modal_show";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [images, setImages] = useState([]);
  const [imagesPrev, setImagesPrev] = useState([]);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const handleCloseHandler = () => {
    setAddModal(false);
    setImgErr("");
    setImagesPrev([]);
    setImages([]);
  };

  const imagesHandler = (e) => {
    const { files } = e.target;
    let imgErr = "";

    if (files.length > 0) {
      let teImage = [];
      for (let key in files) {
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            imgErr = "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            imgErr = "Image size should not be greater than 50MB";
            continue;
          }
          teImage.push(files[key]);
        }
      }

      const tempPrev = [...imagesPrev];
      for (let i = 0; i < teImage.length; i++) {
        tempPrev.push(URL.createObjectURL(teImage[i]));
      }

      setImages([...images, ...teImage]);
      setImagesPrev([...tempPrev]);
      setImgErr(imgErr);
    }
  };

  const removeImgHandle = (i) => {
    const imgArr = [...images];
    const imgArrPreview = [...imagesPrev];
    imgArr.splice(i, 1);
    imgArrPreview.splice(i, 1);

    setImages(imgArr);
    setImagesPrev(imgArrPreview);
  };

  const validate = () => {
    let isValid = false;
    if (images.length === 0) {
      setImgErr("You must have to select atleast 1 image");
    } else {
      isValid = true;
    }
    return isValid;
  };

  const addImagesHandler = async () => {
    if (validate()) {
      const formData = new FormData();
      setAddModal(false);
      setUploadLoading(true);
      let tempImage = [];
      for (let i = 0; i < images.length; i++) {
        if (typeof images[i] !== "string") {
          formData.append("file", images[i]);
          let temp = await dispatch(fileUpload(formData));
          if (temp) {
            tempImage.push(temp.payload);
          }
          formData.delete("file");
        } else {
          tempImage.push(images[i]);
        }
      }

      const data = {
        image: tempImage,
      };

      dispatch(
        createResImg({
          data,
          toast: t("toast.add_res_img"),
          cb: (err, res) => {
            if (err) {
              setUploadLoading(false);
              console.log("err", err);
            } else {
              setUploadLoading(false);
              setAddModal(false);
              setImagesPrev([]);
              setImages([]);
              setImgErr("");
              // if (+pageNumber !== 1) {
              //   navigate("/images/page/1");
              // } else {
              dispatch(getResImg({ start: 0, limit: 1000000 }));
              // }
            }
          },
        })
      );
    }
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleCloseHandler}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal oc-restaurant-image-upload-modal ${showHideClassName}`}
      >
        <h2 className="announcement_page-view-modal">
          {t("common.add") + " " + t("common.images")}
        </h2>
        <div className="announcement_page-view_input-container">
          <label
            className="comman_btn oc-restaurant-image-upload-btn"
            htmlFor="restaurant-image-input"
          >
            {t("common.upload") + " " + t("common.images")}
          </label>
          <Input
            id="restaurant-image-input"
            type="file"
            multiple={true}
            accept=".jpg, .jpeg, .png"
            name="images"
            value=""
            onChange={imagesHandler}
            className="oc-restaurant-image-upload-modal-input"
          />
        </div>

        <div className="oc-restaurant-image-upload-image-row">
          {imagesPrev?.map((item, index) => {
            return (
              <div className="oc-restaurant-image-upload-image-col" key={index}>
                <button onClick={(e) => removeImgHandle(index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <img
                  src={item}
                  alt="item"
                  onClick={() => {
                    setImgZoom(true);
                    setImgSrc(item);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="error-container">
          <span className="modal_err">{imgErr ? imgErr : ""}</span>
        </div>
        <div className="delete_feedback_btn-container">
          <button
            type="button"
            onClick={handleCloseHandler}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
          >
            {t("common.cancel")}
          </button>
          <button
            type="button"
            onClick={addImagesHandler}
            className={`user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20 ${
              uploadLoading && "disable_btn_when_uploading"
            }`}
            disabled={uploadLoading}
          >
            {t("common.add")}
          </button>
        </div>
      </section>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="modal-img" />
      </div>
    </>
  );
};

export default ImageModal;

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Input from "../../component/Common/Input";

const SortableImages = (props) => {
  const { images, checkValue, checkboxOnImgOnChangeHandler } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: images.id,
  });

  const style = {
    transition,
    zIndex: isDragging ? "100" : "auto",
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div>
      <Input
        className="oc-image-restaurant-gallery-col-close-btn oc-image-restaurant-gallery-input"
        labelClassName={`create_from_radio_label_role`}
        inputClassName="create_from_radio_checkbox"
        type="checkbox"
        name="checkboxOnImg"
        value={images.id}
        onChange={(e) => checkboxOnImgOnChangeHandler(e, images)}
        checked={checkValue?.includes(images.id)}
      />
      <div
        className="oc-image-restaurant-gallery-col"
        key={images.id}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <img
          className="oc-image-restaurant-gallery-col-img cursor_pointer_image_view"
          id={images.id}
          src={`${process.env.REACT_APP_FILE_BASE_URL}/${images.image}`}
          alt="restaurant"
        />
      </div>
    </div>
  );
};

export default SortableImages;

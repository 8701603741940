import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import i18next from "i18next";
import "react-toastify/dist/ReactToastify.css";
import common_en from "./translations/en/common.json";
import common_th from "./translations/th/common.json";
import { I18nextProvider } from "react-i18next";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem("language") || "en", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    th: {
      common: common_th,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

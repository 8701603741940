import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { routes } from "../../constants";
import Loader from "../../component/Common/Loader";
import {
  clearLastTabData,
  getMenuItemList,
  updateMenuItem,
} from "../../store/slice/menuSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteMenuItemModal from "./DeleteMenuItemModal";
import { useForm } from "../../hooks/useForm";
import ViewMenu from "./ViewMenu";
import { getCategory } from "../../store/slice/categorySlice";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import MenuListTable from "../../component/Common/PageComponent/MenuComponents/MenuListTable";
import Button from "../../component/Common/Button";
import { reorder } from "../../helpers/commonFunction";
import CopyMenuItem from "./CopyMenuItem";

const Menu = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const [openCopyItemModal, setOpenCopyItemModal] = useState(false);
  const [copiedMenuData, setCopiedMenuData] = useState();

  const [uploadLoading, setUploadLoading] = useState(false);

  const ref = useRef(false);

  let menuItems = useSelector((state) => state.menuItem.menuItemList);
  let menuLoader = useSelector((state) => state.menuItem.loader);

  let categories = useSelector((state) => state.category.categoryList?.data);

  const categoryOptions = categories?.map((item) => ({
    value: item.id,
    label: item.category_name,
  }));

  const allCategoryOptions = categoryOptions && [
    { value: "", label: "All" },
    ...categoryOptions,
  ];

  const initialFValues = {
    viewModal: false,
    viewModalData: "",
    category: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    dispatch(getCategory({ start: 0, limit: 10000000 }));
  }, []);

  useEffect(() => {
    dispatch(
      getMenuItemList({
        category_name:
          values.category?.label === "All" ? "" : values.category?.label,
      })
    );
  }, [values.category]);

  //Delete Item
  const deleteMenuItem = (item) => {
    setDeleteModal(true);
    setDeleteId(item.id);
  };

  //Copy Item
  const copyMenuItem = (item) => {
    setOpenCopyItemModal(true);
    setCopiedMenuData(item);
  };

  //close delete Modal
  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  //close copy Modal
  const handleCloseCopyModal = () => {
    setOpenCopyItemModal(false);
  };

  //View icon validation
  const viewItemHandler = (menu) => {
    setValues({ ...values, viewModal: true, viewModalData: menu });
  };

  const viewHandleClose = () => {
    setValues({ ...values, viewModal: false, viewModalData: "" });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      menuItems,
      result.source.index,
      result.destination.index
    );
    ref.current = true;
    menuItems = items;
  };

  const saveMenuItemsHandler = () => {
    for (let i = 0; i < menuItems.length; i++) {
      const data = {
        menu_name: menuItems[i].menu_name,
        order_number: i + 1,
      };

      dispatch(
        updateMenuItem({
          data,
          id: menuItems[i].id,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              if (i === menuItems.length - 1) {
                dispatch(
                  getMenuItemList({
                    category_name:
                      values.category?.label === "All"
                        ? ""
                        : values.category?.label,
                  })
                );
                toast.success(t("toast.update_menu_list"), {
                  autoClose: 2000,
                });
                ref.current = false;
              }
            }
          },
        })
      );
    }
  };

  const activeMenuItemHandler = (menu, name) => {
    const data = {
      active_status: menu.active_status === "1" ? "0" : "1",
    };
    dispatch(
      updateMenuItem({
        data,
        id: menu.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getMenuItemList({
                category_name:
                  values.category?.label === "All"
                    ? ""
                    : values.category?.label,
              })
            );
            toast.success(
              menu.active_status === "0"
                ? t("table.menu") + " " + t("toast.available_msg")
                : t("table.menu") + " " + t("toast.unavailable_msg"),
              {
                autoClose: 2000,
              }
            );
            ref.current = false;
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("table.menu")}</h2>
          <div className="creat_edit_project_btn_row">
            <Link to={routes.createMenu} className="comman_btn ml-auto">
              {t("common.create")}
            </Link>
          </div>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Select
              placeholder={t("common.category")}
              value={values.category}
              onChange={(e) => handleInputChange(e, "category")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="category"
              options={allCategoryOptions}
            />
          </div>
        </div>
        {menuLoader ? (
          <Loader />
        ) : menuItems?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="custom_data_table_content"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <Button
                    buttonClassName={`comman_btn mb-15 ${
                      !ref.current && "disabled_btn"
                    }`}
                    className="create_from_bottom_btn"
                    onClick={() => saveMenuItemsHandler()}
                    text={t("common.save")}
                    disabled={!ref.current}
                  />
                  <MenuListTable
                    menuItems={menuItems}
                    viewItemHandler={viewItemHandler}
                    deleteMenuItem={deleteMenuItem}
                    copyMenuItem={copyMenuItem}
                    activeMenuItemHandler={activeMenuItemHandler}
                  />
                  {menuItems?.length > 0 && (
                    <Button
                      buttonClassName={`comman_btn save_featured ${
                        !ref.current && "disabled_btn"
                      }`}
                      className="create_from_bottom_btn"
                      onClick={() => saveMenuItemsHandler()}
                      text={t("common.save")}
                      disabled={!ref.current}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <DeleteMenuItemModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
          handleClose={handleCloseModal}
        />
        <ViewMenu
          show={values.viewModal}
          handleClose={viewHandleClose}
          menuDetail={values.viewModalData}
        />
        <CopyMenuItem
          openCopyItemModal={openCopyItemModal}
          setOpenCopyItemModal={setOpenCopyItemModal}
          copiedMenuData={copiedMenuData}
          setCopiedMenuData={setCopiedMenuData}
          handleCloseCopyModal={handleCloseCopyModal}
          uploadLoading={uploadLoading}
          setUploadLoading={setUploadLoading}
          categoryFilter={values.category?.label}
        />
      </div>
    </>
  );
};

export default Menu;

import React, { useState } from "react";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { addIcon, editIcon } from "../../icons";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../hooks/useForm";
import TextArea from "../../component/Common/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import {
  createCategory,
  updateCategory,
} from "../../store/slice/categorySlice";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";

const CreateCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.category;
  const pageNumber = location?.state?.pageNumber;

  const { t, i18n } = useTranslation("common");

  const loading = useSelector((state) => state.category.loader);

  const [uploading, setUploading] = useState(false);

  // let initialImagePreview;

  // if (editComponent?.category_image) {
  //   initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editComponent?.category_image}`;
  // }

  const initialFValues = {
    // category_image: editComponent ? editComponent?.category_image : "",
    // category_image_preview: editComponent ? initialImagePreview : "",
    category_name: editComponent ? editComponent?.category_name : "",
    // description: editComponent ? editComponent?.description : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("category_image" in fieldValues) {
    //   temp.category_image = "";
    //   if (!fieldValues.category_image) {
    //     temp.category_image = t("errors.icon_require");
    //   }
    //   if (fieldValues.category_image.size > 5000000) {
    //     temp.category_image = t("errors.icon_size_val");
    //     setValues({
    //       ...values,
    //       category_image_preview: "",
    //       category_image: "",
    //     });
    //   }
    //   if (
    //     fieldValues.category_image.name &&
    //     !fieldValues.category_image.name?.match(/.(jpg|jpeg|png|gif)$/i)
    //   ) {
    //     temp.category_image = t("errors.icon_invalid");
    //     setValues({
    //       ...values,
    //       category_image_preview: "",
    //       category_image: "",
    //     });
    //   }
    // }

    if ("category_name" in fieldValues) {
      temp.category_name = fieldValues.category_name ? "" : t("errors.name");
    }

    // if ("description" in fieldValues) {
    //   temp.description = fieldValues.description ? "" : t("errors.description");
    // }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  // const imageRemoveHandler = () => {
  //   setValues({ ...values, category_image: "", category_image_preview: "" });
  // };

  const categoryHandler = async () => {
    if (validate()) {
      // setUploading(true);
      // const formData = new FormData();
      // let categoryImgUrl;
      // if (values.category_image && typeof values.category_image !== "string") {
      //   formData.append("file", values.category_image);
      //   categoryImgUrl = await dispatch(fileUpload(formData));
      //   formData.delete("file");
      // } else {
      //   categoryImgUrl = values.category_image;
      // }

      // setUploading(false);
      const data = {
        category_name: values.category_name,
        // category_image:
        //   typeof values.category_image !== "string"
        //     ? categoryImgUrl.payload
        //     : categoryImgUrl,
        // description: values.description,
      };
      editComponent
        ? dispatch(
            updateCategory({
              data,
              id: editComponent.id,
              navigate,
              toast: t("toast.update_category"),
            })
          )
        : dispatch(
            createCategory({
              data,
              navigate,
              toast: t("toast.create_category"),
            })
          );
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {editComponent
            ? `${t("common.category")} > ${t("common.edit")}`
            : `${t("common.category")} > ${t("common.create")}`}
        </h2>
        <button
          onClick={() =>
            navigate(`${routes.category}/page/${pageNumber}`, {
              state: { updateForm: true },
            })
          }
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      {uploading || loading ? (
        <Loader />
      ) : (
        <div className="project_edit_main_content">
          <div className="create_from_row">
            {/* <div className="project_edit_detail_column_content"> */}
            {/* <div className="project_edit_detail_divider"> */}
            {/* <label className="create_from_label required">
                  {t("table.image")}
                </label> */}
            {/* <div className="pr+oject_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`category_image`}
                    labelOtherProps={
                      <span>{!values.category_image ? addIcon : editIcon}</span>
                    }
                    name="category_image"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.category_image,
                      values.category_image_preview,
                      imageRemoveHandler
                    )}
                  />
                </div> */}

            {/* <p className="create_project_review mb-0 block">
                  {t("form.imageValid_mb")}
                </p>
                {errors?.category_image && (
                  <span className="err_text">{errors?.category_image}</span>
                )} */}
            {/* </div> */}
            {/* </div> */}

            <div className="project_edit_feature_input mt-0">
              <Input
                className="project_edit_feature_input mt-0 mb-15"
                labelClassName="create_from_label required"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.name_place")}
                label={t("announcement.title")}
                id="category_name"
                name="category_name"
                value={values.category_name}
                onChange={handleInputChange}
                error={errors?.category_name || ""}
              />
              {/* <TextArea
                className="create_from_input_content col_span_2"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("announcement.description_place")}
                label={t("announcement.description")}
                id="description"
                name="description"
                rows={2}
                value={values.description}
                onChange={handleInputChange}
                error={errors?.description || ""}
              /> */}
            </div>
          </div>
          <Button
            className="project_submit_bottom_btn center_back_btn"
            buttonClassName="comman_btn"
            onClick={categoryHandler}
            text={editComponent ? t("common.update") : t("common.save")}
          />
        </div>
      )}
    </>
  );
};

export default CreateCategory;

import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewFeedbackReview = ({ show, closeFeedbackViewModal, reviewData }) => {
  const { t } = useTranslation("common");
  const showHideClassName = show && "user_detail_modal_show";

  const starRating = (count) => {
    let arr = [
      { id: 1, active: false },
      { id: 2, active: false },
      { id: 3, active: false },
      { id: 4, active: false },
      { id: 5, active: false },
    ];
    for (let i = 0; i < count; i++) {
      arr[i].active = true;
    }
    return arr;
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={closeFeedbackViewModal}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">{`${t(
            "feedback.head"
          )} > ${t("common.view")}`}</h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.user_name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {reviewData.user_name ? reviewData.user_name : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.ratings")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {reviewData.star ? (
                    <div className="oc_feedback_rating_star_view">
                      {starRating(reviewData.star).map((item) => {
                        return (
                          <svg
                            key={item.id}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            fill={item.active ? "#ffce57" : "#cccccc"}
                            width={14}
                            height={14}
                          >
                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                          </svg>
                        );
                      })}
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("feedback.head") + " " + t("table.date")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {reviewData.feedback_date
                    ? moment
                        .utc(reviewData.feedback_date, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.review")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {reviewData.review ? reviewData.review : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {moment
                    .utc(reviewData.created_at, "YYYYMMDD HH:mm:ss")
                    .clone()
                    .tz(defaultTimeZone)
                    .format("DD/MM/YYYY, hh:mm A")}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {reviewData.updated_at
                    ? moment
                        .utc(reviewData.updated_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={closeFeedbackViewModal}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
    </>
  );
};

export default ViewFeedbackReview;

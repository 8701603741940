import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import faviconWhite from "../../images/onecitylogo/FaviconWhite.png";
import { forgotPasswordAdmin } from "../../store/slice/userSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFValues = {
    email: "",
  };

  const validate = (fieldValues = values) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailReg = new RegExp(mailformat);
    var temp = { ...errors };

    if ("email" in fieldValues) {
      if (!fieldValues.email) {
        temp.email = "Email is required!";
      } else {
        temp.email = fieldValues.email.match(mailReg)
          ? ""
          : "Please enter a valid email!";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        email: values.email,
      };
      dispatch(forgotPasswordAdmin({ data, navigate }));
    }
  };

  return (
    <section className="login_section">
      <div className="login_content">
        <div className="login_top_content">
          <h3>Welcome Back !</h3>
          <p>Sign in to One City Centre</p>
        </div>
        <div className="login_input_logo_content">
          <div className="login_logo">
            <img src={faviconWhite} alt="company_logo" />
          </div>
          <form className="login_input_content">
            <Input
              className="login_input_row null"
              errorClassName="err_text"
              type="email"
              placeholder="Email"
              label="Email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors?.email || ""}
            />
            <Button
              type="submit"
              buttonClassName="login_btn"
              onClick={forgotPasswordHandler}
              text="Forgot Password"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { defaultTimeZone } from "../../helpers/commonFunction";

const ViewMenu = ({ show, handleClose, menuDetail }) => {
  const { t } = useTranslation("common");
  const showHideClassName = show && "user_detail_modal_show";
  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const currency = "฿";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">{`${t(
            "table.menu"
          )}> ${t("common.view")}`}</h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.category_name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {menuDetail?.category_name}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.menu_name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {menuDetail?.menu_name}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.preparation_time")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {menuDetail?.preparation_time
                    ? menuDetail?.preparation_time + " Minutes"
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("announcement.description_view")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {menuDetail?.description}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {" "}
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {menuDetail?.created_at
                    ? moment
                        .utc(menuDetail.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {" "}
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {menuDetail?.updated_at
                    ? moment
                        .utc(menuDetail.updated_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.tags")}
                </h2>

                <div className="inquiry_complaint_detail_name">
                  {menuDetail?.tags?.map((tag, i) => {
                    return (
                      <span className="menu_multiple_item_view" key={i}>
                        {tag + (i == menuDetail?.tags?.length - 1 ? "" : ", ")}
                      </span>
                    );
                  })}
                </div>
              </div>
              {menuDetail?.menu_price ? (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("form.price")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {menuDetail?.menu_price + " " + currency}
                  </p>
                </div>
              ) : null}

              {menuDetail?.addons?.length === 0 ? (
                ""
              ) : (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("form.add-ons")}
                  </h2>
                  <div className="inquiry_complaint_detail_name">
                    <div className="custom_map_container">
                      <div className="menu_multiple_item_view_heading_row">
                        <p>Addon Name</p>
                        <span>{"Addon Price" + " (" + currency + ")"}</span>
                      </div>
                      <div className="menu_multiple_item_view">
                        {menuDetail.addons?.map((item, i) => {
                          return (
                            <div className="menu_multiple_item_content" key={i}>
                              <p>{item.addon_name}</p>
                              <span>{item.price}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {menuDetail?.variations?.length === 0 ? (
                ""
              ) : (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("form.variations")}
                  </h2>
                  <div className="inquiry_complaint_detail_name">
                    {menuDetail?.variations?.map((variation, i) => {
                      return (
                        <div key={i} className="custom_map_container">
                          <div className="menu_multiple_item_view">
                            {"Variation: " + variation.var_name}
                          </div>
                          <div className="menu_multiple_item_view_heading_row">
                            <p>Item Name</p>
                            <span>{"Item Price" + " (" + currency + ")"}</span>
                          </div>
                          <div className="menu_multiple_item_view">
                            {variation.item.map((item, i) => {
                              return (
                                <div
                                  className="menu_multiple_item_content"
                                  key={i}
                                >
                                  <p>{item.item_name}</p>
                                  <span>{item.item_price}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {menuDetail?.custom_options?.length === 0 ? (
                ""
              ) : (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("form.custom_options")}
                  </h2>
                  <div className="inquiry_complaint_detail_name">
                    {menuDetail?.custom_options?.map((option, i) => {
                      return (
                        <div key={i} className="custom_map_container">
                          <div className="menu_multiple_item_view">
                            {"Option Name: " + option.custom_name}
                          </div>
                          <div className="menu_multiple_item_view_heading_row">
                            <p>Item Name</p>
                            <span>{"Item Price" + " (" + currency + ")"}</span>
                          </div>
                          <div className="menu_multiple_item_view">
                            {option.custom_item?.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="menu_multiple_item_content"
                                >
                                  <p>{item.item_name}</p>
                                  <span>{item.item_price}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.images")}
                </h2>
                <div className="inquiry_complaint_detail_name">
                  {menuDetail?.image ? (
                    <div className="menu_img_selected_container">
                      <div className="menu_img_selected_images_content view_img_container">
                        {menuDetail?.image.map((item, i) => {
                          return (
                            <div key={i}>
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "5px",
                                  marginRight: "0.75rem",
                                  cursor: "pointer",
                                }}
                                src={`${process.env.REACT_APP_FILE_BASE_URL}/${item}`}
                                alt="item_image"
                                key={i}
                                onClick={() => {
                                  setImgZoom(true);
                                  setImgSrc(
                                    process.env.REACT_APP_FILE_BASE_URL +
                                      "/" +
                                      item
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="view-table-img" />
      </div>
    </>
  );
};

export default ViewMenu;

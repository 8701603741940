import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getMenuItemList, updateMenuItem } from "../../store/slice/menuSlice";
import { toast } from "react-toastify";

const DeleteMenuItemModal = ({
  deleteModal,
  setDeleteModal,
  deleteId,
  setDeleteId,
  handleClose,
}) => {
  const showHideClassName = deleteModal && "user_detail_modal_show";
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const deleteMenu = () => {
    const data = {
      delete_status: "1",
    };
    dispatch(
      updateMenuItem({
        data,
        id: deleteId,
        cb: (err, res) => {
          if (err) {
          } else {
            dispatch(getMenuItemList({}));
            setDeleteModal(false);
            setDeleteId("");
            toast.error(t("toast.delete_menu_item"), {
              autoClose: 3000,
            });
          }
        },
      })
    );
  };
  return (
    <>
      <div
        className={`user_detail_modal_bg ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("modal.delete.delete_menu")}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.delete_menu_desc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
              onClick={handleClose}
            >
              {t("common.close")}
            </button>

            <button
              type="button"
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
              onClick={deleteMenu}
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteMenuItemModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios, { authHeaders } from "../../helpers/axios";

export const getFeedback = createAsyncThunk("getFeedback", async (data) => {
  try {
    const response = await Axios.get(
      `/onecity-restaurant/feedback?user_id=${data.user_id || ""}&start=${
        data.start || 0
      }&limit=${data.limit || 10}`,
      authHeaders()
    );
    return { data: response.data, count: response.headers["x-total-count"] };
  } catch (error) {
    toast.error(data.error, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

const feedbackSlice = createSlice({
  name: "feedBack",
  initialState: {
    feedbackList: [],
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedback.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFeedback.fulfilled, (state, action) => {
      state.loader = false;
      state.feedbackList = action.payload;
    });
    builder.addCase(getFeedback.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default feedbackSlice.reducer;

export const routes = {
  homepage: "/",
  all: "*",
  login: "/login",
  forgotPassword: "/forgotPassword",
  forgotpasswordcheckyouremail: "/forgotpasswordcheckyouremail",
  forgotpasswordPage: "/onecity-restaurant/restaurant/confirm-password/:token",

  dashboard: "/dashboard",

  restaurant: "/restaurant",
  changePassword: "/restaurant-change-password",

  images: "/images",
  // imagesByPage: "/images/page/:page_number",

  category: "/category",
  categoryByPage: "/category/page/:page_number",
  createCategory: "/create-category",
  editCategory: "/category/edit/:id",

  tag: "/tag",
  tagByPage: "/tag/page/:page_number",

  menuItem: "/menu",
  createMenu: "/create-menu",
  editMenu: "/edit-menu/:id",

  // menuImages: "/m-image",
  // menuImagesByPage: "/m-image/page/:page_number",

  promoCode: "/promo-code",
  promoCodeByPage: "/promo-code/page/:page_number",
  createPromoCode: "/create-promo-code",
  editPromoCode: "/promo-code/edit/:id",

  // cutlery: "/cutlery",
  // cutleryByPage: "/cutlery/page/:page_number",
  // createCutlery: "/create-cutlery",
  // updateCutlery: "/cutlery/:id",

  order: "/order",
  orderByPage: "/order/page/:page_number",

  feedback: "/feedback",
  feedbackByPage: "/feedback/page/:page_number",
};

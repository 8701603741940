import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getOrderById,
  getOrderList,
  updateOrderItem,
} from "../../store/slice/ordersSlice";

const DeleteItemModal = ({
  showItemDeleteModal,
  handleClose,
  deleteOrderItemHandler,
}) => {
  const { t } = useTranslation("common");
  const showHideClassName = showItemDeleteModal && "user_detail_modal_show";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
      >
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {t("modal.delete.remove_menu")}
          </h2>
          <h4 className="announcement_page-view-modal delete_feedback_subheading">
            {t("modal.delete.remove_menu_desc")}
          </h4>
          <div className="delete_feedback_btn-container">
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
            >
              {t("common.close")}
            </button>
            <button
              type="button"
              onClick={deleteOrderItemHandler}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
            >
              {t("contact.remove")}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteItemModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

// Create Addon
export const createAddOn = createAsyncThunk("createAddOn", async (data) => {
  try {
    const response = await Axios.post(
      `/onecity-restaurant/addon`,
      data.data,
      authHeaders()
    );
    if (response.status === 200 || response.status === 201) {
      toast.success(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.addOn}`);
      return response.data;
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    console.log("error:", error.response);
  }
});

//Update Addon
export const updateAddOn = createAsyncThunk("updateAddOn", async (data) => {
  try {
    const response = await Axios.put(
      `/onecity-restaurant/addon/${data.id}`,
      data.data,
      authHeaders()
    );
    if (response.status === 200 && !data.cb) {
      toast.success(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.addOn}`);
      return response.data;
    }
    if (data.cb) {
      return data.cb(null, response.data);
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
  }
});

//Get Addon
export const getAddOn = createAsyncThunk("getAddOn", async (data) => {
  try {
    const response = await Axios.get(
      `/onecity-restaurant/addon`,
      authHeaders()
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

const addOnSlice = createSlice({
  name: "addOn",
  initialState: {
    addOnList: [],
    createAddOn: "",
    updateAddOn: "",
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //createAddon
    builder.addCase(createAddOn.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(createAddOn.fulfilled, (state, action) => {
      state.loader = false;
      state.createAddOn = action.payload;
    });
    builder.addCase(createAddOn.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getAddOn
    builder.addCase(getAddOn.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getAddOn.fulfilled, (state, action) => {
      state.loader = false;
      state.addOnList = action.payload;
    });
    builder.addCase(getAddOn.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //updateAddOn
    builder.addCase(updateAddOn.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(updateAddOn.fulfilled, (state, action) => {
      state.loader = false;
      state.updateAddOn = action.payload;
    });
    builder.addCase(updateAddOn.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default addOnSlice.reducer;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderById,
  getOrderList,
  updateOrder,
  updateOrderItem,
} from "../../store/slice/ordersSlice";
import { toast } from "react-toastify";
import {
  // checkIcon,
  closeIcon,
  phoneIcon,
  // crossIcon,
  // errorIcon,
  // pendingIcon,
  // processingIcon,
} from "../../icons";
import Loader from "../../component/Common/Loader";
import DeleteItemModal from "./DeleteItemModal";

const ViewOrder = ({
  viewOrderClose,
  show,
  setViewModal,
  orderDate,
  orderStatusFilter,
  viewModalId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const showHideClassName = show && "user_detail_modal_show";

  // const [tabId, setTabId] = useState("0");

  const [orderStatus, setOrderStatus] = useState("");
  const [showItemDeleteModal, setShowItemDeleteModal] = useState(false);
  const [itemDeleteId, setItemDeleteId] = useState("");
  const [orderCurrentStatus, setOrderCurrentStatus] = useState("");

  // const [orderData, setOrderData] = useState({});
  const orderData = useSelector((state) => state.order.orderDetail);
  const extraLoader = useSelector((state) => state.order.extraLoader);

  useEffect(() => {
    dispatch(getOrderById({ id: viewModalId }));
  }, []);

  useEffect(() => {
    setOrderCurrentStatus(orderData.order_status);
    setOrderStatus(orderData.order_status);
  }, [orderData]);

  const statusList = [
    {
      display_name: t("service.pending"),
      value: "PENDING",
      disableValue: orderCurrentStatus === "PENDING" ? true : false,
    },
    {
      display_name: t("service.accepted"),
      value: "ACCEPTED",
      disableValue:
        orderCurrentStatus === "PENDING" || orderCurrentStatus === "ACCEPTED"
          ? true
          : false,
    },
    {
      display_name: t("service.process"),
      value: "PROCESSING",
      disableValue:
        orderCurrentStatus === "ACCEPTED" || orderCurrentStatus === "PROCESSING"
          ? true
          : false,
    },
    {
      display_name: t("service.completed"),
      value: "COMPLETE",
      disableValue:
        orderCurrentStatus === "PROCESSING" || orderCurrentStatus === "COMPLETE"
          ? true
          : false,
    },
    {
      display_name: t("service.picked_up"),
      value: "DELIVERED",
      disableValue:
        orderCurrentStatus === "COMPLETE" || orderCurrentStatus === "DELIVERED"
          ? true
          : false,
    },
    {
      display_name: t("service.Cancelled"),
      value: "CANCELLED",
      disableValue:
        orderCurrentStatus === "DELIVERED" || orderCurrentStatus === "COMPLETE"
          ? false
          : true,
    },
  ];

  const changeOrderStatusHandler = () => {
    const data = {
      order_status: orderStatus,
    };
    dispatch(
      updateOrder({
        id: orderData.id,
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getOrderList({
                order_status: orderStatusFilter,
                order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
              })
            );
            setViewModal(false);
            toast.success(t("toast.update_order"), {
              autoClose: 3000,
            });
          }
        },
      })
    );
  };

  const currency = "฿";

  // delete order Item from order view page
  const deleteOrderItemHandler = () => {
    const data = {
      delete_status: "1",
    };

    dispatch(
      updateOrderItem({
        id: itemDeleteId,
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getOrderList({
                order_status: orderStatusFilter,
                order_date: orderDate && moment(orderDate).format("YYYY-MM-DD"),
              })
            );
            if (orderData.orders.length > 1) {
              dispatch(getOrderById({ id: viewModalId }));
              setShowItemDeleteModal(false);
            } else {
              setViewModal(false);
              setShowItemDeleteModal(false);
            }
            toast.error("Order Item deleted successfully", {
              autoClose: 3000,
            });
          }
        },
      })
    );
  };

  const handleCloseHandler = () => {
    setShowItemDeleteModal(false);
    setItemDeleteId("");
  };

  const showDeleteModalhandler = (deleteItemId) => {
    setShowItemDeleteModal(true);
    setItemDeleteId(deleteItemId);
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={viewOrderClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        {extraLoader ? (
          <Loader />
        ) : (
          <div className="inquiry_complaint_detail_section">
            <h2 className="announcement_page-view-modal">
              {`${t("common.order")} > ${t("common.view")}`}
            </h2>

            <div className="inquiry_complaint_detail_content">
              <div className="inquiry_complaint_detail_body view-modal_container">
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.order_no")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {orderData.order_number}
                  </p>
                </div>
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.user_name")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {orderData.user_name}
                  </p>
                </div>

                {orderData.order_notes && (
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      {t("table.order_notes")}
                    </h2>
                    <p className="inquiry_complaint_detail_name">
                      {orderData.order_notes && orderData.order_notes}
                    </p>
                  </div>
                )}

                {orderData.no_product && (
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      {t("table.product_not_available")}
                    </h2>
                    {orderData.no_product === "CALL_ME" ? (
                      <p className="inquiry_complaint_detail_name">
                        {t("form.call_me")}
                      </p>
                    ) : orderData.no_product === "CANCEL_ORDER" ? (
                      <p className="inquiry_complaint_detail_name">
                        {t("form.cancel_the_order")}
                      </p>
                    ) : orderData.no_product === "REMOVE_FROM_ORDER" ? (
                      <p className="inquiry_complaint_detail_name">
                        {t("form.remove_from_order")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {orderData.no_product === "CALL_ME" && (
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      {t("table.phoneNumber")}
                    </h2>
                    <div className="inquiry_complaint_detail_phone_number_link">
                      <p className="inquiry_complaint_detail_name">
                        +{orderData.contact_number}
                      </p>
                      <a href={`tel:${orderData.contact_number}`}>
                        {phoneIcon}
                      </a>
                    </div>
                  </div>
                )}

                {/* {orderData.promo_code && (
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      {t("table.promo_code")}
                    </h2>
                    <p className="inquiry_complaint_detail_name">
                      {orderData.promo_code}
                    </p>
                  </div>
                )} */}
                {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.order_status")}
                </h2>
                <div className="inquiry_complaint_detail_name">
                  {orderData.order_status === "PENDING" ? (
                    <p className="text_with_icon_container">
                      <span className="text_with_icon">{pendingIcon}</span>{" "}
                      <span className="text_with_icon">
                        {t("service.pending")}
                      </span>
                    </p>
                  ) : orderData.order_status === "ACCEPTED" ||
                    orderData.order_status === "PROCESSING" ? (
                    <p>
                      <span className="text_with_icon">{processingIcon}</span>{" "}
                      {orderData.order_status === "ACCEPTED" ? (
                        <span className="text_with_icon">
                          {t("service.accepted")}
                        </span>
                      ) : (
                        <span className="text_with_icon">
                          {t("service.process")}
                        </span>
                      )}
                    </p>
                  ) : orderData.order_status === "COMPLETE" ? (
                    <p>
                      <span className="text_with_icon">{errorIcon}</span>{" "}
                      <span className="text_with_icon">
                        {t("service.completed")}
                      </span>
                    </p>
                  ) : orderData.order_status === "DELIVERED" ? (
                    <p>
                      <span className="text_with_icon">{checkIcon}</span>{" "}
                      <span className="text_with_icon">
                        {t("service.picked_up")}
                      </span>
                    </p>
                  ) : orderData.order_status === "CANCELLED" ? (
                    <p>
                      <span className="text_with_icon">{crossIcon}</span>{" "}
                      <span className="text_with_icon">
                        {t("service.Cancelled")}
                      </span>
                    </p>
                  ) : (
                    orderData.order_status
                  )}
                </div>
              </div> */}
                {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.total_amount")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.total} {currency}
                </p>
              </div> */}
                {/* <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.cutlery")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.is_cutlery == "0" ? "No" : "Yes"}
                </p>
              </div> */}
              </div>
              {/* <div className="tab span_2" onChange={(e) => changeTabHandler(e)}>
              {orderData.orders?.map((orderItem, i) => {
                return (
                  <input
                    label={`${t("common.item")}` + " " + (i + 1)}
                    type="radio"
                    id="order"
                    name="forAll"
                    value={i}
                    checked={tabId == i}
                    readOnly
                    key={i}
                  />
                );
              })}
            </div> */}
              {/* <div className="inquiry_complaint_detail_body view-modal_container multiple_window_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.category")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.orders[tabId].category_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.menu_name")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.orders[tabId].menu_name}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.quantity")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.orders[tabId].quantity}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.preparation_time")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.orders[tabId].preparation_time} minutes
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.menu") + " " + t("form.price")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.orders[tabId].menu_price + " " + currency}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("common.item") + " " + t("table.total_amount")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {orderData.orders[tabId].total_price + " " + currency}
                </p>
              </div>
              {orderData.orders[tabId]?.cutlery_name && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("form.cutlery_name")}
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {orderData.orders[tabId].cutlery_name
                      ? orderData.orders[tabId].cutlery_name
                      : "-"}
                  </p>
                </div>
              )}
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.menu") + " " + t("common.tags")}
                </h2>
                <div className="inquiry_complaint_detail_name">
                  {JSON.parse(orderData.orders[tabId].tags)?.map((tag, i) => {
                    return (
                      <span className="menu_multiple_item_view" key={i}>
                        {tag +
                          (i ==
                          JSON.parse(orderData.orders[tabId].tags)?.length - 1
                            ? ""
                            : ", ")}
                      </span>
                    );
                  })}
                </div>
              </div>
              {JSON.parse(orderData.orders[tabId].addons)?.length !== 0 && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.menu") + " " + t("form.add-ons")}
                  </h2>
                  <div className="inquiry_complaint_detail_name">
                    <div className="custom_map_container">
                      <div className="menu_multiple_item_view_heading_row">
                        <p>Addon Name</p>
                        <span>{"Addon Price" + " (" + currency + ")"}</span>
                      </div>
                      <div className="menu_multiple_item_view">
                        {JSON.parse(orderData.orders[tabId].addons)?.map(
                          (item, i) => {
                            return (
                              <div
                                className="menu_multiple_item_content"
                                key={i}
                              >
                                <p>{item.addon_name}</p>
                                <span>{item.addon_price}</span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {JSON.parse(orderData.orders[tabId].variations)?.length !== 0 && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("table.menu") + " " + t("form.variations")}
                  </h2>
                  <div className="inquiry_complaint_detail_name">
                    {JSON.parse(orderData.orders[tabId].variations)?.map(
                      (variation, i) => {
                        return (
                          <div key={i} className="custom_map_container">
                            <div className="menu_multiple_item_view">
                              {"Variation: " + variation.var_name}
                            </div>
                            <div className="menu_multiple_item_view_heading_row">
                              <p>Item Name</p>
                              <span>
                                {"Item Price" + " (" + currency + ")"}
                              </span>
                            </div>
                            <div className="menu_multiple_item_view">
                              <div className="menu_multiple_item_content">
                                <p>{variation.item_name}</p>
                                <span>{variation.item_price}</span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {JSON.parse(orderData.orders[tabId].custom_options)?.length !==
                0 && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    {t("form.custom_options")}
                  </h2>
                  <div className="inquiry_complaint_detail_name">
                    {JSON.parse(orderData.orders[tabId].custom_options)?.map(
                      (option, i) => {
                        return (
                          <div key={i} className="custom_map_container">
                            <div className="menu_multiple_item_view">
                              {"Option Name: " + option.custom_name}
                            </div>
                            <div className="menu_multiple_item_view_heading_row">
                              <p>Item Name</p>
                              <span>
                                {"Item Price" + " (" + currency + ")"}
                              </span>
                            </div>
                            <div className="menu_multiple_item_view">
                              {option.item?.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="menu_multiple_item_content"
                                  >
                                    <p>{item.item_name}</p>
                                    <span>{item.custom_price}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div> */}
              <div className="detail_page_main_container">
                <div className="detail_page_second_container">
                  <div className="custom_data_table_content custom_data_order_content">
                    <div className="custom_data_table_order_list">
                      <div className="oc-order-view-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Order Details</th>
                              <th></th>
                              <th>Total Price(฿)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderData.orders?.map((item, index) => {
                              return (
                                ((item.delete_status === "0" &&
                                  orderData.order_status !== "CANCELLED") ||
                                  orderData.order_status === "CANCELLED") && (
                                  <tr
                                    key={item.id}
                                    className="oc-order-view-table-content"
                                  >
                                    <td>
                                      <div className="oc-order-view-table-quantity">
                                        {item.quantity}x
                                      </div>
                                    </td>
                                    <td>
                                      <div className="oc-order-view-table-detail-text">
                                        <span>
                                          {item.menu_name} {"("}
                                          {item.category_name}
                                          {")"}
                                        </span>
                                        <div className="oc-order-view-table-detail-coloumn">
                                          {/* {JSON.parse(item.tags)?.length !==
                                          0 && (
                                          <div className="oc-order-view-table-detail-container">
                                            <h2 className="oc-order-view-table-detail-heading">
                                              Tags{" "}
                                            </h2>
                                            {JSON.parse(item.tags)?.map(
                                              (tag, i) => {
                                                return <p key={i}>{tag}</p>;
                                              }
                                            )}
                                          </div>
                                        )} */}
                                          {item.menu_price && (
                                            <div className="oc-order-view-table-detail-container">
                                              <h2 className="oc-order-view-table-detail-heading">
                                                Basic
                                              </h2>
                                              <p>
                                                (
                                                {item.menu_price +
                                                  " " +
                                                  currency}
                                                )
                                              </p>
                                            </div>
                                          )}

                                          {JSON.parse(item.variations)
                                            ?.length !== 0 && (
                                            <div className="oc-order-view-table-detail-container">
                                              <h2 className="oc-order-view-table-detail-heading">
                                                Variations
                                              </h2>

                                              {JSON.parse(item.variations)?.map(
                                                (variation, i) => {
                                                  return (
                                                    <p key={variation.id}>
                                                      {variation.item_name} (
                                                      {variation.item_price +
                                                        " " +
                                                        currency}
                                                      )
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                          {JSON.parse(item.addons)?.length !==
                                            0 && (
                                            <div className="oc-order-view-table-detail-container">
                                              <h2 className="oc-order-view-table-detail-heading">
                                                Add-ons
                                              </h2>

                                              {JSON.parse(item.addons)?.map(
                                                (addon, i) => {
                                                  return (
                                                    <p key={addon.id}>
                                                      {addon.addon_name} (
                                                      {addon.price +
                                                        " " +
                                                        currency}
                                                      )
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                          {JSON.parse(item.custom_options)
                                            ?.length !== 0 && (
                                            <div className="oc-order-view-table-detail-container">
                                              <h2 className="oc-order-view-table-detail-heading">
                                                Custom Options
                                              </h2>
                                              {JSON.parse(
                                                item.custom_options
                                              )?.map((option, i) => {
                                                return (
                                                  <div
                                                    className="oc-order-view-table-detail-custom-name"
                                                    key={option.id}
                                                  >
                                                    {/* <h2 className="">
                                                    {option.custom_name} :{" "}
                                                  </h2> */}
                                                    <p className="oc-order-view-table-detail-custom-item">
                                                      {option.item?.map(
                                                        (item, i) => {
                                                          return (
                                                            <p key={i}>
                                                              {item.item_name +
                                                                " (" +
                                                                item.custom_price +
                                                                " " +
                                                                currency +
                                                                ")" +
                                                                (i ==
                                                                option.item
                                                                  .length -
                                                                  1
                                                                  ? ""
                                                                  : ", ")}
                                                            </p>
                                                          );
                                                        }
                                                      )}
                                                    </p>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="oc-order-view-table-price-text">
                                        {item.total_price + " " + currency}
                                      </div>
                                    </td>
                                    {orderData.no_product ===
                                      "REMOVE_FROM_ORDER" &&
                                      (orderData.order_status === "PENDING" ||
                                        orderData.order_status === "ACCEPTED" ||
                                        orderData.order_status ===
                                          "PROCESSING") && (
                                        <button
                                          className="menu_item_delete_button"
                                          onClick={() =>
                                            showDeleteModalhandler(item.id)
                                          }
                                        >
                                          {closeIcon}
                                        </button>
                                      )}
                                  </tr>
                                )
                              );
                            })}
                            {orderData.is_cutlery == "1" && (
                              <tr className="oc-order-view-table-cultery-row">
                                <td></td>
                                <td>
                                  <div className="oc-order-view-table-detail-text">
                                    <span>Need Cultery</span>
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            )}
                            {orderData.promo_code && (
                              <>
                                <tr className="oc-order-view-table-cultery-row">
                                  <td></td>
                                  <td>
                                    <div className="oc-order-view-table-detail-text">
                                      <span>Total</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="oc-order-view-table-price-text">
                                      {orderData.amount + " " + currency}
                                    </div>
                                  </td>
                                </tr>
                                <tr className="oc-order-view-table-content">
                                  <td></td>
                                  <td>
                                    <div className="oc-order-view-table-detail-text">
                                      <span>Discount</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="oc-order-view-table-price-text">
                                      {"(" +
                                        orderData.promo_code +
                                        "" +
                                        (orderData.discount_type ===
                                        "PERCENTAGE"
                                          ? " - " +
                                            orderData.promo_code_discount +
                                            "%"
                                          : "") +
                                        ") -" +
                                        orderData.discount +
                                        "" +
                                        currency}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )}
                            <tr className="oc-order-view-table-cultery-row">
                              <td></td>
                              <td>
                                <div className="oc-order-view-table-detail-text">
                                  <span>Total Amount</span>
                                </div>
                              </td>
                              <td>
                                <div className="oc-order-view-table-price-text">
                                  {orderData.total + " " + currency}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="oc-order-view-status-btn">
                <div className="visitor-button-row visitor-button-row1">
                  {statusList.map((btn, i) => {
                    return (
                      <button
                        key={i}
                        className={`visitor-button ${
                          orderStatus === btn.value && "is-active"
                        } ${!btn.disableValue && "disabled-visitor-button"}`}
                        value={btn.value}
                        onClick={(e) => setOrderStatus(e.target.value)}
                        disabled={!btn.disableValue}
                      >
                        {btn.display_name}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              {orderStatus !== orderData.order_status && (
                <button
                  type="button"
                  onClick={changeOrderStatusHandler}
                  className="user_detail_modal_close_btn comman_btn modal_save_btn"
                >
                  {t("common.save")}
                </button>
              )}
              <button
                type="button"
                onClick={viewOrderClose}
                className="user_detail_modal_close_btn comman_btn secondary_btn"
              >
                {t("common.close")}
              </button>
            </div>
          </div>
        )}
      </section>
      <DeleteItemModal
        showItemDeleteModal={showItemDeleteModal}
        handleClose={handleCloseHandler}
        deleteOrderItemHandler={deleteOrderItemHandler}
      />
    </>
  );
};

export default ViewOrder;

import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Input";
import { addIcon, minusIcon, plusIcon, removeIcon } from "../../../../icons";

const CustomsInput = ({
  customOptionList,
  setCustomOptionList,
  customOptionErr,
  setCustomOptionErr,
  remove_customs,
  setRemove_customs,
  menuDetail,
}) => {
  const { t } = useTranslation("common");

  const addCustomOption = () => {
    setCustomOptionList([
      ...customOptionList,
      {
        custom_name: "",
        custom_type: "SINGLE",
        min_select: 0,
        max_select: 0,
        custom_item: [{ item_name: "", item_price: "" }],
      },
    ]);
    setCustomOptionErr("");
  };

  const removeCustomOption = (index) => {
    const list = [...customOptionList];
    list.splice(index, 1);
    setCustomOptionList(list);
    setCustomOptionErr("");

    const customId = customOptionList[index]?.id;
    const existingIndex = remove_customs?.findIndex(
      (obj) => obj.id === customId
    );

    if (existingIndex >= 0) {
      const items = customOptionList[index]?.custom_item?.map((i) => i.id);
      const existingItems = remove_customs[existingIndex].item;
      const missingItems = items?.filter((id) => !existingItems.includes(id));

      if (missingItems?.length > 0) {
        setRemove_customs((prev) => {
          const updated = [...prev];
          updated[existingIndex].item.push(...missingItems);
          return updated;
        });
      }
    } else if (customId) {
      // Object doesn't exist, add a new one
      const items = customOptionList[index]?.custom_item?.map((i) => i.id);
      setRemove_customs((prev) => [...prev, { id: customId, item: items }]);
    }
  };

  const clearFirstCustom = (index) => {
    setCustomOptionErr("");

    const customId = customOptionList[index]?.id;
    const existingIndex = remove_customs?.findIndex(
      (obj) => obj.id === customId
    );

    if (existingIndex >= 0) {
      const items = customOptionList[index]?.custom_item?.map((i) => i.id);
      const existingItems = remove_customs[existingIndex].item;
      const missingItems = items?.filter((id) => !existingItems.includes(id));

      if (missingItems?.length > 0) {
        setRemove_customs((prev) => {
          const updated = [...prev];
          updated[existingIndex].item.push(...missingItems);
          return updated;
        });
      }
    } else if (customId) {
      // Object doesn't exist, add a new one
      const items = customOptionList[index]?.custom_item?.map((i) => i.id);
      setRemove_customs((prev) => [...prev, { id: customId, item: items }]);
    }

    setCustomOptionList([
      {
        custom_name: "",
        custom_type: "SINGLE",
        min_select: 0,
        max_select: 0,
        custom_item: [{ item_name: "", item_price: "" }],
      },
    ]);
  };

  const addCustomItem = (customIndex) => {
    const newItem = { item_name: "", item_price: "" };
    const list = [...customOptionList];
    const custom_item = [...list[customIndex].custom_item, newItem];
    list[customIndex] = { ...list[customIndex], custom_item };
    setCustomOptionList(list);
    setCustomOptionErr("");
  };

  const removeCustomItem = (customIndex, customItemIndex) => {
    const newList = customOptionList.map((customOption, index) => {
      if (index === customIndex) {
        return {
          ...customOption,
          max_select:
            customOption.custom_item.length <= customOption.max_select
              ? customOption.max_select - 1
              : customOption.max_select,
          min_select:
            customOption.custom_item.length <= customOption.min_select
              ? customOption.min_select - 1
              : customOption.min_select,
          custom_item: [
            ...customOption.custom_item.slice(0, customItemIndex),
            ...customOption.custom_item.slice(customItemIndex + 1),
          ],
        };
      }
      return customOption;
    });
    setCustomOptionList(newList);
    setCustomOptionErr("");

    if (
      customOptionList[customIndex]?.id &&
      customOptionList[customIndex]?.custom_item[customItemIndex]?.id
    ) {
      const existingIndex = remove_customs.findIndex(
        (obj) => obj?.id === customOptionList[customIndex]?.id
      );

      if (existingIndex !== -1) {
        const updatedItem = [
          ...remove_customs[existingIndex].item,
          customOptionList[customIndex]?.custom_item[customItemIndex]?.id,
        ];
        const updatedRemove_customs = [...remove_customs];
        updatedRemove_customs[existingIndex] = {
          id: customOptionList[customIndex]?.id,
          item: updatedItem,
        };
        setRemove_customs(updatedRemove_customs);
      } else {
        setRemove_customs([
          ...remove_customs,
          {
            id: customOptionList[customIndex]?.id,
            item: [
              customOptionList[customIndex]?.custom_item[customItemIndex]?.id,
            ],
          },
        ]);
      }
    }
  };

  const customOptionNameChangeHandler = (e, customIndex) => {
    const { name, value } = e.target;
    const list = [...customOptionList];
    const updatedOptionName = { ...list[customIndex], [name]: value };
    list[customIndex] = updatedOptionName;
    setCustomOptionList(list);
    setCustomOptionErr("");
  };

  const customOptionTypeChangeHandler = (e, customIndex) => {
    const { value } = e.target;
    const initialName = "custom_type";
    const list = [...customOptionList];
    const updatedOptionType = {
      ...list[customIndex],
      [initialName]: value,
      min_select: value === "SINGLE" ? 0 : 1,
      max_select: value === "SINGLE" ? 0 : 1,
    };
    list[customIndex] = updatedOptionType;
    setCustomOptionList(list);
    setCustomOptionErr("");
  };

  const customItemChangeHandler = (e, customIndex, customItemIndex) => {
    const { name, value } = e.target;

    if (name === "item_price") {
      if (!new RegExp(/^([0-9][0-9]*(\.?[5,0]{1})?)?$/).test(value)) return;
    }

    const list = [...customOptionList];
    const updatedItem = {
      ...list[customIndex].custom_item[customItemIndex],
      [name]: value,
    };
    const updatedItemList = [...list[customIndex].custom_item];
    updatedItemList[customItemIndex] = updatedItem;
    const updatedList = [...list];
    updatedList[customIndex] = {
      ...list[customIndex],
      custom_item: updatedItemList,
    };
    setCustomOptionList(updatedList);
    setCustomOptionErr("");
  };

  const minMaxInputChangeHandler = (minOrMax, method, customIndex) => {
    let inputName = minOrMax === "min" ? "min_select" : "max_select";
    const list = [...customOptionList];
    const updatedOptionName = {
      ...list[customIndex],
      [inputName]:
        method === "dec" && list[customIndex][inputName] === 1
          ? 1
          : method === "dec"
          ? list[customIndex][inputName] - 1
          : list[customIndex][inputName] + 1,
    };
    if (
      minOrMax == "min" &&
      method == "inc" &&
      customOptionList[customIndex].max_select <=
        customOptionList[customIndex].min_select + 1
    ) {
      updatedOptionName.max_select =
        customOptionList[customIndex].min_select + 1;
    }
    list[customIndex] = updatedOptionName;
    setCustomOptionList(list);
    setCustomOptionErr("");
  };

  return (
    <div className="add_on_container oc_custom_menu_tab_menu_input_content">
      <>
        <h4 className="mobile_configuration_heading">
          {t("form.custom_options")}
        </h4>

        {customOptionList.map((option, i) => (
          <div key={i} className="add_on_input_content">
            <div className="add_on_input_container">
              {/* <Input
                className="create_from_input_content null"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                label={t("form.custom_option_name")}
                name="custom_name"
                value={option.custom_name}
                placeholder={t("form.enter_custom_option_name")}
                type="text"
                onChange={(e) => customOptionNameChangeHandler(e, i)}
              /> */}

              <div className="create_from_input_content null">
                <div className="oc_menu_item_main_content_input_label_row">
                  <label className="create_from_label required">
                    {t("form.custom_option_name")}
                  </label>
                  <div className="add_on_input_content_btn_row">
                    {customOptionList.length !== 1 && (
                      <button
                        className="add_on_input_content_remove_btn"
                        onClick={() => removeCustomOption(i)}
                      >
                        {/* {t("contact.remove")} */}
                        {minusIcon}
                      </button>
                    )}
                    {customOptionList.length - 1 === i && (
                      <button
                        className="add_on_input_content_add_btn"
                        onClick={() => addCustomOption()}
                      >
                        {/* {t("contact.add")} */}
                        {plusIcon}
                      </button>
                    )}
                    {customOptionList.length === 1 && menuDetail && (
                      <button
                        className="add_on_input_content_remove_btn"
                        // onClick={() => removeFirstCustomOption(i)}
                        onClick={(e) => clearFirstCustom(i)}
                      >
                        {minusIcon}
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    className="create_from_input"
                    type="text"
                    placeholder={t("form.enter_custom_option_name")}
                    name="custom_name"
                    value={option.custom_name}
                    onChange={(e) => customOptionNameChangeHandler(e, i)}
                  />
                </div>
              </div>
              <div className="create_role_heaing_radio_row_input">
                <label className="create_from_label required undefined">
                  {t("form.custom_option_select_type")}
                </label>
                <div className="custom_option_type_radio_row">
                  <Input
                    className="create_from_radio_checkbox_contentainer"
                    labelClassName={`create_from_radio_label_role`}
                    inputClassName="create_from_radio_checkbox"
                    type="radio"
                    label={t("common.single")}
                    name={"custom_type" + i}
                    id={"Single" + 100 + i}
                    value="SINGLE"
                    onChange={(e) => customOptionTypeChangeHandler(e, i)}
                    checked={option.custom_type === "SINGLE"}
                  />
                  <Input
                    className="create_from_radio_checkbox_contentainer"
                    labelClassName={`create_from_radio_label_role`}
                    inputClassName="create_from_radio_checkbox"
                    type="radio"
                    label={t("common.multiple")}
                    name={"custom_type" + i}
                    id={"Multiple" + 10 + i}
                    value="MULTIPLE"
                    onChange={(e) => customOptionTypeChangeHandler(e, i)}
                    checked={option.custom_type === "MULTIPLE"}
                  />
                </div>
              </div>
              {option.custom_type === "MULTIPLE" && (
                <>
                  <div className="create_role_heaing_radio_row_input">
                    <label className="create_from_label required">
                      {"Minimum select"}
                    </label>
                    <div className="cumstoms_min_min_value">
                      <button
                        className="custom_decrement_btn"
                        id="my-button"
                        onClick={() =>
                          minMaxInputChangeHandler("min", "dec", i)
                        }
                        disabled={option.min_select === 1}
                      >
                        -
                      </button>
                      <p id="count" className="counts">
                        {option.min_select}
                      </p>
                      <button
                        className="custom_increment_btn"
                        id="my-button2"
                        disabled={
                          option.custom_item.length == option.min_select
                        }
                        onClick={() =>
                          minMaxInputChangeHandler("min", "inc", i)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="create_role_heaing_radio_row_input">
                    <label className="create_from_label required">
                      {"Maximum select"}
                    </label>
                    <div className="cumstoms_min_min_value">
                      <button
                        className="custom_decrement_btn"
                        id="my-button"
                        onClick={() =>
                          minMaxInputChangeHandler("max", "dec", i)
                        }
                        disabled={
                          option.max_select === 1 ||
                          option.min_select == option.max_select
                        }
                      >
                        -
                      </button>
                      <p id="count" className="counts">
                        {option.max_select}
                      </p>
                      <button
                        className="custom_increment_btn"
                        id="my-button2"
                        onClick={() =>
                          minMaxInputChangeHandler("max", "inc", i)
                        }
                        disabled={
                          option.custom_item.length == option.max_select
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </>
              )}

              {option.custom_item.map((item, j) => {
                return (
                  <div className="oc_variations_two_input_btn_row" key={j}>
                    <div className="oc_variations_two_input_row">
                      <Input
                        className="create_from_input_content null"
                        labelClassName="create_from_label required"
                        inputClassName="create_from_input"
                        errorClassName="err_text"
                        label={t("form.item_name")}
                        name="item_name"
                        type="text"
                        placeholder={t("placeholder.enter_item_name")}
                        value={item.item_name}
                        onChange={(e) => customItemChangeHandler(e, i, j)}
                      />
                      <Input
                        className="create_from_input_content null"
                        labelClassName="create_from_label required"
                        inputClassName="create_from_input"
                        errorClassName="err_text"
                        label={t("form.item_price")}
                        name="item_price"
                        placeholder={t("form.enter_item_price")}
                        type="text"
                        value={item.item_price}
                        onChange={(e) => customItemChangeHandler(e, i, j)}
                      />
                    </div>
                    <div className="add_on_input_content_btn_row span_2">
                      {option.custom_item.length !== 1 && (
                        <button
                          className="add_on_input_content_remove_btn"
                          onClick={() => removeCustomItem(i, j)}
                        >
                          {minusIcon}
                        </button>
                      )}
                      {option.custom_item.length - 1 === j && (
                        <button
                          className="add_on_input_content_add_btn"
                          onClick={() => addCustomItem(i)}
                        >
                          {plusIcon}
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        {customOptionErr && <span className="err_text">{customOptionErr}</span>}
      </>
    </div>
  );
};

export default CustomsInput;

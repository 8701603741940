import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Input";
import { addIcon, minusIcon, plusIcon, removeIcon } from "../../../../icons";

const VariationsInput = ({
  variationList,
  setvariationList,
  variationErr,
  setVariationErr,
  remove_variations,
  setRemove_variations,
  menuDetail,
}) => {
  const { t } = useTranslation("common");

  const handleAddVariation = () => {
    setvariationList([
      ...variationList,
      {
        var_name: "",
        item: [{ item_name: "", item_price: "0", is_default: "1" }],
      },
    ]);
    setVariationErr("");
  };

  const handleRemoveVariation = (index) => {
    const list = [...variationList];
    list.splice(index, 1);
    setvariationList(list);
    setVariationErr("");

    const variationId = variationList[index]?.id;
    // Check if an object with the same id exists in remove_variations
    const existingIndex = remove_variations?.findIndex(
      (obj) => obj.id === variationId
    );

    if (existingIndex >= 0) {
      // Object already exists, update its item property if necessary
      const items = variationList[index]?.item?.map((i) => i.id);
      const existingItems = remove_variations[existingIndex].item;
      const missingItems = items?.filter((id) => !existingItems.includes(id));

      if (missingItems?.length > 0) {
        setRemove_variations((prev) => {
          const updated = [...prev];
          updated[existingIndex].item.push(...missingItems);
          return updated;
        });
      }
    } else if (variationId) {
      // Object doesn't exist, add a new one
      const items = variationList[index]?.item?.map((i) => i.id);
      setRemove_variations((prev) => [
        ...prev,
        { id: variationId, item: items },
      ]);
    }
  };

  const handleAddItem = (varIndex) => {
    const newItem = { item_name: "", item_price: "", is_default: "0" };
    const list = [...variationList];
    const item = [...list[varIndex].item, newItem];
    list[varIndex] = { ...list[varIndex], item };
    setvariationList(list);
    setVariationErr("");
  };

  const handleRemoveItem = (varIndex, itemIndex) => {
    const newList = variationList.map((variation, index) => {
      if (index === varIndex) {
        const updatedItem = variation.item.filter((item, i) => i !== itemIndex);
        const hasDefaultItem = updatedItem.some(
          (item) => item.is_default === "1"
        );

        if (!hasDefaultItem && updatedItem.length > 0) {
          updatedItem[0] = { ...updatedItem[0], is_default: "1" }; // Create a new object with updated is_default value
        }

        return {
          ...variation,
          item: updatedItem,
        };
      }
      return variation;
    });
    setvariationList(newList);
    setVariationErr("");

    if (
      variationList[varIndex]?.id &&
      variationList[varIndex]?.item[itemIndex]?.id
    ) {
      const existingIndex = remove_variations.findIndex(
        (obj) => obj?.id === variationList[varIndex]?.id
      );

      if (existingIndex !== -1) {
        const updatedItem = [
          ...remove_variations[existingIndex].item,
          variationList[varIndex]?.item[itemIndex]?.id,
        ];
        const updatedRemove_variations = [...remove_variations];
        updatedRemove_variations[existingIndex] = {
          id: variationList[varIndex]?.id,
          item: updatedItem,
        };
        setRemove_variations(updatedRemove_variations);
      } else {
        setRemove_variations([
          ...remove_variations,
          {
            id: variationList[varIndex]?.id,
            item: [variationList[varIndex]?.item[itemIndex]?.id],
          },
        ]);
      }
    }
  };

  const clearFirstVariation = (index) => {
    setVariationErr("");

    const variationId = variationList[index]?.id;
    // Check if an object with the same id exists in remove_variations
    const existingIndex = remove_variations?.findIndex(
      (obj) => obj.id === variationId
    );

    if (existingIndex >= 0) {
      // Object already exists, update its item property if necessary
      const items = variationList[index]?.item?.map((i) => i.id);
      const existingItems = remove_variations[existingIndex].item;
      const missingItems = items?.filter((id) => !existingItems.includes(id));

      if (missingItems?.length > 0) {
        setRemove_variations((prev) => {
          const updated = [...prev];
          updated[existingIndex].item.push(...missingItems);
          return updated;
        });
      }
    } else if (variationId) {
      // Object doesn't exist, add a new one
      const items = variationList[index]?.item?.map((i) => i.id);
      setRemove_variations((prev) => [
        ...prev,
        { id: variationId, item: items },
      ]);
    }
    setvariationList([
      {
        var_name: "",
        item: [{ item_name: "", item_price: "0", is_default: "1" }],
      },
    ]);
  };

  const varNameChangeHandler = (e, varIndex) => {
    const { value } = e.target;
    const list = [...variationList];
    const updatedVar = { ...list[varIndex], var_name: value };
    list[varIndex] = updatedVar;
    setvariationList(list);
    setVariationErr("");
  };

  const varItemChangeHandler = (e, varIndex, itemIndex) => {
    const { name, value } = e.target;

    if (name === "item_price") {
      if (!new RegExp(/^([0-9][0-9]*(\.?[5,0]{1})?)?$/).test(value)) return;
    }

    const list = [...variationList];
    const updatedItem = { ...list[varIndex].item[itemIndex], [name]: value };
    const updatedItemList = [...list[varIndex].item];
    updatedItemList[itemIndex] = updatedItem;
    const updatedList = [...list];
    updatedList[varIndex] = { ...list[varIndex], item: updatedItemList };
    setvariationList(updatedList);
    setVariationErr("");
  };

  const isDefaultChangeHandler = (varIndex, itemIndex) => {
    const initialName = "is_default";
    const list = [...variationList];
    const updatedItemList = list[varIndex].item.map((item, index) => {
      if (index === itemIndex) {
        return { ...item, [initialName]: "1", item_price: 0 };
      } else {
        return { ...item, [initialName]: "0" };
      }
    });
    const updatedList = [...list];
    updatedList[varIndex] = { ...list[varIndex], item: updatedItemList };
    setvariationList(updatedList);
    setVariationErr("");
  };

  return (
    <div className="create_role_heaing_radio_row_input oc_variations_tab_menu_input_content">
      <div className="add_on_container">
        <div className="oc_menu_item_main_content_main_heading">
          <h4 className="mobile_configuration_heading">
            {t("form.variations")}
          </h4>
        </div>
        {variationList.map((variation, i) => (
          <div key={i} className="add_on_input_content">
            <div className="add_on_input_container">
              {/* <Input
                className="create_from_input_content null span_2"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                label={t("form.variation_name")}
                name="var_name"
                value={variation.var_name}
                placeholder={t("common.enter") + " " + t("form.variation_name")}
                type="text"
                onChange={(e) => varNameChangeHandler(e, i)}
              /> */}
              <div className="create_from_input_content null span_2">
                <div className="oc_menu_item_main_content_input_label_row">
                  <label className="create_from_label required">
                    {t("form.variation_name")}
                  </label>
                  <div className="add_on_input_content_btn_row">
                    {variationList.length !== 1 && (
                      <button
                        className="add_on_input_content_remove_btn"
                        onClick={() => handleRemoveVariation(i)}
                      >
                        {minusIcon}
                      </button>
                    )}
                    {variationList.length - 1 === i && (
                      <button
                        className="add_on_input_content_add_btn"
                        onClick={() => handleAddVariation()}
                      >
                        {plusIcon}
                      </button>
                    )}
                    {variationList.length === 1 && menuDetail && (
                      <button
                        className="add_on_input_content_remove_btn"
                        // onClick={(e) => handleRemoveFirstVariation(i)}
                        onClick={(e) => clearFirstVariation(i)}
                      >
                        {minusIcon}
                      </button>
                    )}
                  </div>
                  {/* <Input
                    className="create_from_radio_checkbox_contentainer"
                    labelClassName={`create_from_radio_label_role`}
                    inputClassName="create_from_radio_checkbox_variation"
                    type="radio"
                    label={"Is Default"}
                    name={"is_default" + i}
                    id={"is_default" + i + j}
                    value={item.is_default}
                    onChange={(e) => isDefaultChangeHandler(i, j)}
                    checked={item.is_default === "1"}
                  /> */}
                </div>
                <div>
                  <input
                    className="create_from_input"
                    type="text"
                    placeholder={
                      t("common.enter") + " " + t("form.variation_name")
                    }
                    name="var_name"
                    value={variation.var_name}
                    onChange={(e) => varNameChangeHandler(e, i)}
                  />
                </div>
              </div>
              {variation.item.map((item, j) => {
                return (
                  <div className="oc_variations_two_input_btn_row" key={j}>
                    <div className="oc_variations_two_input_row">
                      <Input
                        className="create_from_input_content null"
                        labelClassName="create_from_label required"
                        inputClassName="create_from_input"
                        errorClassName="err_text"
                        label={t("form.item_name")}
                        name="item_name"
                        type="text"
                        placeholder={t("placeholder.enter_item_name")}
                        value={item.item_name}
                        onChange={(e) => varItemChangeHandler(e, i, j)}
                      />
                      {/* <Input
                      className="create_from_input_content null"
                      labelClassName="create_from_label required"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      label={t("form.item_price")}
                      name="item_price"
                      placeholder={t("form.enter_item_price")}
                      type="text"
                      value={item.item_price}
                      onChange={(e) => varItemChangeHandler(e, i, j)}
                    /> */}
                      <div className="create_from_input_content null">
                        <div className="oc_menu_item_main_content_input_label_row">
                          <label className="create_from_label required">
                            {t("form.item_price")}
                          </label>
                          <Input
                            className="create_from_radio_checkbox_contentainer"
                            labelClassName={`create_from_radio_label_role`}
                            inputClassName="create_from_radio_checkbox_variation"
                            type="radio"
                            label={"Is Default"}
                            name={"is_default" + i}
                            id={"is_default" + i + j}
                            value={item.is_default}
                            onChange={(e) => isDefaultChangeHandler(i, j)}
                            checked={item.is_default === "1"}
                          />
                        </div>
                        <div>
                          <input
                            className={`create_from_input ${
                              item.is_default === "1" && "disable_input_field"
                            }`}
                            type="text"
                            placeholder={t("form.enter_item_price")}
                            name="item_price"
                            value={
                              item.is_default === "1" ? 0 : item.item_price
                            }
                            disabled={item.is_default === "1" ? true : false}
                            // value={item.item_price}
                            onChange={(e) => varItemChangeHandler(e, i, j)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="add_on_input_content_btn_row span_2">
                      
                    </div> */}

                    <div className="add_on_input_content_btn_row span_2">
                      {variation.item.length !== 1 && (
                        <button
                          className="add_on_input_content_remove_btn"
                          onClick={() => handleRemoveItem(i, j)}
                        >
                          {minusIcon}
                        </button>
                      )}
                      {variation.item.length - 1 === j && (
                        <button
                          className="add_on_input_content_add_btn"
                          onClick={() => handleAddItem(i)}
                        >
                          {plusIcon}
                        </button>
                      )}
                      {/* {variation.item.length !== 1 && (
                        <button
                          className="add_on_input_content_remove_btn"
                          onClick={() => handleRemoveItem(i, j)}
                        >
                          {removeIcon}{" "}
                          {t("contact.remove") + " " + t("common.item")}
                        </button>
                      )} */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        {variationErr && <span className="err_text">{variationErr}</span>}
      </div>
    </div>
  );
};

export default VariationsInput;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "../../hooks/useForm";
import Input from "../../component/Common/Input";
import Select from "react-select";
import Button from "../../component/Common/Button";
import { useNavigate, useLocation } from "react-router-dom";
import CommonDatePicker from "../../component/Common/DatePicker";
import {
  updatePromoCode,
  createPromoCode,
} from "../../store/slice/promoCodeSlice";
import moment from "moment";
import { routes } from "../../constants";
import { defaultTimeZone } from "../../helpers/commonFunction";

export const dateFunction = (time) => {
  return moment(`2016/12/08 ${time}`, "YYYY/MM/DD h:m:s A").format(
    "YYYY/MM/DD HH:mm:ss"
  );
};

const CreatePromoCode = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const editComponent = location?.state?.promo;

  const pageNumber = location?.state?.pageNumber;

  const discountTypeOptions = [
    { value: "FIXED", label: "Fix" },
    { value: "PERCENTAGE", label: "Percentage" },
  ];

  const initialFValues = {
    discount_type: editComponent
      ? editComponent?.discount_type == "PERCENTAGE"
        ? { value: "PERCENTAGE", label: "Percentage" }
        : { value: "FIXED", label: "Fix" }
      : "",
    discount: editComponent ? editComponent?.discount : "",
    minimum_order: editComponent ? editComponent?.minimum_order : "",
    promo_code: editComponent ? editComponent?.code : "",
    max_discount: editComponent ? editComponent?.max_discount : "",
    max_usage: editComponent ? editComponent?.max_usage : "",
    max_usage_per_user: editComponent ? editComponent?.max_usage_per_user : "",
    start_date: editComponent
      ? new Date(
          moment
            .utc(editComponent?.start_date, "YYYYMMDD HH:mm:ss")
            .clone()
            .tz(defaultTimeZone)
            .format("MM/DD/YYYY, kk:mm:ss")
        )
      : "",
    end_date: editComponent
      ? new Date(
          moment
            .utc(editComponent?.end_date, "YYYYMMDD HH:mm:ss")
            .clone()
            .tz(defaultTimeZone)
            .format("MM/DD/YYYY, kk:mm:ss")
        )
      : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("discount_type" in fieldValues) {
      temp.discount_type = fieldValues.discount_type
        ? ""
        : t("errors.discount_type");
    }

    if ("discount" in fieldValues) {
      temp.discount = fieldValues.discount
        ? values.discount_type.value === "PERCENTAGE" &&
          fieldValues.discount > 100
          ? t("errors.percentage_discount")
          : ""
        : t("errors.discount");
    }

    if ("minimum_order" in fieldValues) {
      temp.minimum_order = fieldValues.minimum_order
        ? ""
        : "Minimum order amount required";
    }
    if ("max_discount" in fieldValues) {
      temp.max_discount = +fieldValues.max_discount
        ? values.discount_type.value === "FIXED" &&
          +fieldValues.max_discount >= +values.discount
          ? ""
          : values.discount_type.value === "PERCENTAGE"
          ? ""
          : t("errors.max_dis_err")
        : t("errors.max_discount");
    }

    if ("start_date" in fieldValues) {
      temp.start_date = fieldValues.start_date ? "" : t("errors.start_date");
    }

    if ("end_date" in fieldValues) {
      temp.end_date = fieldValues.end_date ? "" : t("errors.end_date");
    }

    if ("max_usage" in fieldValues) {
      temp.max_usage =
        fieldValues.max_usage && fieldValues.max_usage > 0
          ? ""
          : t("errors.max_usage");
    }

    if ("max_usage_per_user" in fieldValues) {
      temp.max_usage_per_user =
        fieldValues.max_usage_per_user && fieldValues.max_usage_per_user > 0
          ? ""
          : t("errors.max_usage_per_user");
    }
    if ("promo_code" in fieldValues) {
      temp.promo_code = fieldValues.promo_code
        ? ""
        : t("errors.enter_promo_code");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const validateStartAndEndDate = () => {
    if (JSON.stringify(values.start_date) === JSON.stringify(values.end_date)) {
      setErrors({
        ...errors,
        end_date: t("errors.start_and_end_date"),
      });
    } else {
      return true;
    }
  };

  const createUpdatePromoCodeHandler = () => {
    if (validate() && validateStartAndEndDate()) {
      const data = {
        discount_type: values?.discount_type.value,
        discount: values?.discount,
        code: values?.promo_code,
        max_discount: values?.max_discount,
        max_usage: values?.max_usage,
        max_usage_per_user: values?.max_usage_per_user,
        minimum_order: values?.minimum_order,
        // start_date: moment(values?.start_date).format("YYYY/MM/DD HH:mm:ss"),
        // end_date: moment(values?.end_date).format("YYYY/MM/DD HH:mm:ss"),
        start_date: moment
          .utc(moment(values?.start_date).utc())
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment
          .utc(moment(values?.end_date).utc())
          .format("YYYY-MM-DD HH:mm:ss"),
      };

      editComponent
        ? dispatch(
            updatePromoCode({
              data,
              id: editComponent?.id,
              navigate,
              toast: t("toast.update_promo"),
            })
          )
        : dispatch(
            createPromoCode({
              data,
              navigate,
              toast: t("toast.create_promo"),
            })
          );
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {editComponent
            ? t("table.promo_code") + " > " + t("common.edit")
            : t("table.promo_code") + " > " + t("common.create")}
        </h2>
        <button
          onClick={() =>
            navigate(`${routes.promoCode}/page/${pageNumber}`, {
              state: { updateForm: true },
            })
          }
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.create_promo_code")}
            label={t("table.promo_code")}
            id="promo_code"
            name="promo_code"
            value={values.promo_code}
            onChange={handleInputChange}
            error={errors.promo_code || ""}
          />
          <div className="login_input_row mb-0">
            <label className="required">{t("table.discount_type")}</label>
            <Select
              placeholder={t("form.select_discount_type")}
              value={values.discount_type}
              className="basic-multi-select"
              classNamePrefix="select"
              name="discount_type"
              options={discountTypeOptions}
              onChange={(e) => handleInputChange(e, "discount_type")}
            />
            {errors.discount_type && (
              <span className="err_text">{errors.discount_type}</span>
            )}
          </div>
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("form.enter_discount")}
            label={t("table.discount")}
            id="discount"
            name="discount"
            value={values.discount}
            onChange={handleInputChange}
            error={errors.discount || ""}
          />

          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName={`create_from_input`}
            errorClassName="err_text"
            type="text"
            placeholder={t("form.enter_max_discount")}
            label={t("table.max_discount")}
            id="max_discount"
            name="max_discount"
            value={values.max_discount}
            onChange={handleInputChange}
            error={errors.max_discount || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName={`create_from_input`}
            errorClassName="err_text"
            type="text"
            placeholder={t("form.enter_max_usage")}
            label={t("table.max_usage")}
            id="max_usage"
            name="max_usage"
            value={values.max_usage}
            onChange={handleInputChange}
            error={errors.max_usage || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName={`create_from_input`}
            errorClassName="err_text"
            type="text"
            placeholder={t("form.enter_max_usage_per_user")}
            label={t("table.max_usage_per_user_label")}
            id="max_usage_per_user"
            name="max_usage_per_user"
            value={values.max_usage_per_user}
            onChange={handleInputChange}
            error={errors.max_usage_per_user || ""}
          />
          <CommonDatePicker
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            label={t("table.start_date")}
            name="start_date"
            dateFormat="dd/MM/yyyy h:mm:ss"
            showTimeSelect={true}
            timeIntervals={30}
            minDate={new Date()}
          />
          <CommonDatePicker
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            label={t("table.end_date")}
            name="end_date"
            dateFormat="dd/MM/yyyy h:mm:ss"
            showTimeSelect={true}
            timeIntervals={30}
            minDate={new Date(values.start_date)}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label required"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder={t("common.enter") + " " + t("table.min_order_amount")}
            label={t("table.min_order_amount")}
            id="minimum_order"
            name="minimum_order"
            value={values.minimum_order}
            onChange={handleInputChange}
            error={errors.minimum_order || ""}
          />
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createUpdatePromoCodeHandler}
          text={editComponent ? t("common.update") : t("common.create")}
        />
      </div>
    </>
  );
};

export default CreatePromoCode;

import React from "react";
import Input from "../../Input";
import { useTranslation } from "react-i18next";
import { minusIcon, plusIcon } from "../../../../icons";

const AddonsInput = ({
  addonErr,
  menuDetail,
  setAddonList,
  addonList,
  setAddonErr,
  setRemove_addons,
  remove_addons,
}) => {
  const { t, i18n } = useTranslation("common");

  const handleAddonItemAdd = () => {
    setAddonList([...addonList, { addon_name: "", price: "" }]);
    setAddonErr("");
  };

  const handleAddonRemove = (index) => {
    const list = [...addonList];
    list.splice(index, 1);
    setAddonList(list);
    setAddonErr("");
    if (addonList[index]?.id) {
      setRemove_addons([...remove_addons, addonList[index]?.id]);
    }
  };

  const clearFirstAddon = () => {
    setAddonErr("");
    setAddonList([{ addon_name: "", price: "" }]);
    if (addonList[1]?.id) {
      setRemove_addons([...remove_addons, addonList[1].id]);
    }
  };

  const addOnInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "price") {
      if (!new RegExp(/^([0-9][0-9]*(\.?[5,0]{1})?)?$/).test(value)) return;
    }

    const list = [...addonList];
    const updatedItem = { ...list[index], [name]: value };
    list[index] = updatedItem;
    setAddonList(list);
    setAddonErr("");
  };

  return (
    <div className="add_on_container">
      <div className="oc_menu_item_main_content_main_heading">
        <h4 className="mobile_configuration_heading">{t("form.add-ons")}</h4>
        {/* {menuDetail && addonList.length === 1 && (
          <button
            className="add_on_input_content_remove_btn"
            onClick={(e) => clearFirstAddon()}
          >
            Clear text
          </button>
        )} */}
      </div>
      {addonList.map((addon, i) => (
        <div key={i} className="add_on_input_content">
          <div className="add_on_input_container">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              label={t("form.item_name")}
              name="addon_name"
              value={addon.addon_name}
              placeholder={t("form.enter_addon_item")}
              type="text"
              onChange={(e) => addOnInputChange(e, i)}
            />
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              label={t("form.item_price")}
              name="price"
              value={addon.price}
              placeholder={t("form.enter_addon_item_price")}
              type="text"
              onChange={(e) => addOnInputChange(e, i)}
            />
          </div>
          <div className="add_on_input_content_btn_row">
            {addonList.length !== 1 && (
              <button
                className=" add_on_input_content_remove_btn"
                onClick={() => handleAddonRemove(i)}
              >
                {minusIcon}
              </button>
            )}
            {addonList.length - 1 === i && (
              <button
                className=" add_on_input_content_add_btn"
                onClick={() => handleAddonItemAdd()}
              >
                {plusIcon}
              </button>
            )}
            {menuDetail && addonList.length === 1 && (
              <button
                className="add_on_input_content_remove_btn"
                onClick={(e) => clearFirstAddon()}
              >
                {minusIcon}
              </button>
            )}
          </div>
        </div>
      ))}
      {addonErr && <span className="err_text">{addonErr}</span>}
    </div>
  );
};

export default AddonsInput;

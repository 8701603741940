import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedback } from "../../store/slice/feedbackSlice";
import Loader from "../../component/Common/Loader";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { starIcon, viewIcon } from "../../icons";
import ViewFeedbackReview from "./ViewFeedbackReview";
import { defaultTimeZone } from "../../helpers/commonFunction";
import Pagination from "../../component/Pagination/Pagination";
import { useParams } from "react-router";

const Feedback = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const feedbackData = useSelector(
    (state) => state.feedback.feedbackList?.data
  );
  const feedbackCount = useSelector(
    (state) => state.feedback.feedbackList?.count
  );
  const feedbackLoader = useSelector((state) => state.feedback.loader);

  const [imgZoom, setImgZoom] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const [viewModal, setViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);

  useEffect(() => {
    if (ref.current) {
      dispatch(getFeedback({ start: (page_number - 1) * 10, limit: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getFeedback({ start: (page_number - 1) * 10, limit: 10 }));
    ref.current = true;
  }, []);

  const viewFeedbackHandler = (feed) => {
    setViewModal(true);
    setViewModalData(feed);
  };

  const closeFeedbackViewModal = () => {
    setViewModal(false);
    setViewModalData("");
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const starRating = (count) => {
    let arr = [
      { id: 1, active: false },
      { id: 2, active: false },
      { id: 3, active: false },
      { id: 4, active: false },
      { id: 5, active: false },
    ];
    for (let i = 0; i < count; i++) {
      arr[i].active = true;
    }
    return arr;
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("feedback.head")}</h2>
        </div>
        {feedbackLoader ? (
          <Loader />
        ) : feedbackData?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <div className="custom_data_table_content">
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.profileImage")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.user_name")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.review")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("feedback.head") + " " + t("table.date")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.ratings")}
                  </th>

                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {feedbackData?.map((feed, index) => {
                  return (
                    <tr className="custom_data_table_row" key={feed.id}>
                      <td className="custom_data_table_item table_item">
                        <img
                          src={
                            feed.profile_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                feed.profile_image
                              : process.env.REACT_APP_FILE_BASE_URL +
                                "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                          }
                          alt="profileImage"
                          className={`custom_data_table_img cursor_pointer_image_view`}
                          onClick={() => {
                            setImgZoom(true);
                            setImgSrc(
                              feed?.profile_image
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                    "/" +
                                    feed?.profile_image
                                : process.env.REACT_APP_FILE_BASE_URL +
                                    "/45/a30a40a1-510a-420a-bfb6-bedb974e15ed.png"
                            );
                          }}
                        />
                      </td>
                      <td className="custom_data_table_item table_item">
                        {feed.user_name ? feed.user_name : "-"}
                      </td>
                      <td className="custom_data_table_item table_item feed_review">
                        {feed.review ? feed.review : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {feed.feedback_date
                          ? moment
                              .utc(feed.feedback_date, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {feed.star ? (
                          <div className="oc_feedback_rating_star_table">
                            {starRating(feed.star).map((item) => {
                              return (
                                <svg
                                  key={item.id}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                  fill={item.active ? "#ffce57" : "#cccccc"}
                                  width={14}
                                  height={14}
                                >
                                  <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                </svg>
                              );
                            })}
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {moment
                          .utc(feed.created_at, "YYYYMMDD HH:mm:ss")
                          .clone()
                          .tz(defaultTimeZone)
                          .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {feed.updated_at
                          ? moment
                              .utc(feed.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => viewFeedbackHandler(feed)}
                            >
                              {viewIcon}
                            </button>

                            <span className="tooltiptext">
                              {t("common.view")}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {feedbackData?.length > 0 && (
          <Pagination
            totalRecords={feedbackCount}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <div
        onClick={() => setImgZoom(false)}
        className={`oc-table-img-modal-bg ${
          imgZoom && "oc-table-img-modal-open"
        }`}
      ></div>
      <div
        className={`oc-table-img-modal ${imgZoom && "oc-table-img-modal-open"}`}
      >
        <img src={imgSrc} alt="profileImage" />
      </div>
      {viewModal && (
        <ViewFeedbackReview
          show={viewModal}
          closeFeedbackViewModal={closeFeedbackViewModal}
          reviewData={viewModalData}
        />
      )}
    </>
  );
};

export default Feedback;

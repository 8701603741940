/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Input from "../../component/Common/Input";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import TextArea from "../../component/Common/TextArea";
import { useForm } from "../../hooks/useForm";
import Button from "../../component/Common/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTag } from "../../store/slice/tagSlice";
import { getCategory } from "../../store/slice/categorySlice";
import { closeIcon } from "../../icons";
import MultiImgSelectModal from "./MultiImgSelectModal";
import {
  createMenuItem,
  getMenuItemById,
  getMenuItemList,
  lastTabData,
  updateMenuItem,
} from "../../store/slice/menuSlice";
import {
  addOnValidation,
  createValidAddOnsList,
  createValidCustomsList,
  createValidVariationsList,
  customsValidation,
  variationValidation,
  getVariationPriceOrMainPrice,
} from "../../component/Common/PageComponent/MenuComponents/MenuItemComponents";
import CustomsInput from "../../component/Common/PageComponent/MenuComponents/CustomsInput";
import VariationsInput from "../../component/Common/PageComponent/MenuComponents/VariationsInput";
import AddonsInput from "../../component/Common/PageComponent/MenuComponents/AddonsInput";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import Loader from "../../component/Common/Loader";
import SetVarPriceInMainModal from "./SetVarPriceInMainModal";
import { toast } from "react-toastify";

const CreateMenu = ({ menuDetail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const tagsList = useSelector((state) => state.tag.tagList?.data);
  const categories = useSelector((state) => state.category.categoryList?.data);
  const lastTab = useSelector((state) => state.menuItem.lastTab);
  const [uploadLoading, setUploadLoading] = useState(false);

  // const [openPriceModal, setOpenPriceModal] = useState(false);

  const [addonList, setAddonList] = useState(
    menuDetail && menuDetail?.addons?.length > 0
      ? menuDetail.addons
      : [{ addon_name: "", price: "" }]
  );
  const [addonErr, setAddonErr] = useState("");

  const [previousMenuPrice, setPreviousMenuPrice] = useState("");
  const [previousVariationList, setPreviousVariationList] = useState([]);

  const [variationList, setvariationList] = useState(
    menuDetail && menuDetail?.variations?.length > 0
      ? menuDetail.variations
      : [
          {
            var_name: "",
            item: [{ item_name: "", item_price: "0", is_default: "1" }],
          },
        ]
  );
  const [variationErr, setVariationErr] = useState("");

  const [customOptionList, setCustomOptionList] = useState(
    menuDetail && menuDetail?.custom_options?.length > 0
      ? menuDetail.custom_options
      : [
          {
            custom_name: "",
            custom_type: "SINGLE",
            min_select: 0,
            max_select: 0,
            custom_item: [{ item_name: "", item_price: "" }],
          },
        ]
  );
  const [customOptionErr, setCustomOptionErr] = useState("");

  const [imgModal, setImgModal] = useState(false);
  const [tab, setTab] = useState(menuDetail && lastTab ? lastTab : "general");

  useEffect(() => {
    dispatch(getTag({ start: 0, limit: 100000000 }));
    dispatch(getCategory({ start: 0, limit: 100000000 }));
  }, []);

  // Tags in Menu
  var tagIds = menuDetail?.tag_id && JSON.parse(menuDetail?.tag_id);
  var tags = menuDetail?.tags && menuDetail?.tags;

  var editTagOptions = [];
  tagIds?.forEach((id, i) =>
    editTagOptions.push({ value: id, label: tags[i] })
  );

  const tagOptions = tagsList?.map((item) => ({
    value: item.id,
    label: item.tag_name,
  }));
  //

  // category in menu
  const categoryOptions = categories?.map((item) => ({
    value: item.id,
    label: item.category_name,
  }));

  // imgs in Menu
  // var imageIds =
  //   menuDetail?.menu_image_id && JSON.parse(menuDetail?.menu_image_id);

  const getprevEditMenuImages = () => {
    var prevEditMenuImages = [];
    menuDetail?.image?.forEach((image, i) =>
      prevEditMenuImages.push(`${process.env.REACT_APP_FILE_BASE_URL}/${image}`)
    );
    return prevEditMenuImages;
  };

  const getEditImages = (images, allImageIds) => {
    var editImages = [];
    var imageIds = allImageIds && JSON.parse(allImageIds);
    imageIds?.forEach((id, i) =>
      editImages.push({ imgUrl: images[i], value: id, selected: true })
    );
    return editImages;
  };

  const [imgData, setImgData] = useState(
    menuDetail && menuDetail?.image?.length > 0
      ? getEditImages(menuDetail?.image, menuDetail?.menu_image_id)
      : []
  );

  const [menuImages, setMenuImages] = useState(
    menuDetail && menuDetail?.image?.length > 0 ? menuDetail.image : []
  );
  const [menuImagesPrev, setMenuImagesPrev] = useState(
    menuDetail && menuDetail?.image?.length > 0 ? getprevEditMenuImages() : []
  );

  const [imgDataErr, setImgDataErr] = useState("");

  const [remove_addons, setRemove_addons] = useState([]);
  const [remove_variations, setRemove_variations] = useState([]);
  const [remove_customs, setRemove_customs] = useState([]);

  useEffect(() => {
    setImgDataErr("");
  }, [imgData]);

  const initialFValues = {
    name: menuDetail && menuDetail.menu_name ? menuDetail.menu_name : "",
    description:
      menuDetail && menuDetail.description ? menuDetail.description : "",
    price: menuDetail && menuDetail?.menu_price ? menuDetail.menu_price : "",
    tags: menuDetail && menuDetail.tags ? editTagOptions : "",
    category:
      menuDetail && menuDetail.category_id
        ? {
            value: menuDetail.category_id,
            label: menuDetail.category_name,
          }
        : "",
    preparationTime:
      menuDetail && menuDetail.preparation_time
        ? menuDetail.preparation_time
        : "",
    menuImages: [],
    recommended:
      menuDetail && menuDetail.recommended ? menuDetail.recommended : "0",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : t("errors.name");
    }

    if ("description" in fieldValues) {
      temp.description = fieldValues.description ? "" : t("errors.description");
    }

    if ("price" in fieldValues) {
      temp.price = fieldValues.price ? "" : t("errors.price");
    }

    if ("tags" in fieldValues) {
      temp.tags =
        fieldValues.tags && fieldValues.tags.length > 0 ? "" : t("errors.tag");
    }

    if ("category" in fieldValues) {
      temp.category = fieldValues.category ? "" : t("errors.category");
    }

    if ("preparationTime" in fieldValues) {
      temp.preparationTime = fieldValues.preparationTime
        ? ""
        : t("errors.time");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    setValues({
      ...values,
      name: menuDetail && menuDetail.menu_name ? menuDetail.menu_name : "",
      description:
        menuDetail && menuDetail.description ? menuDetail.description : "",
      price: menuDetail && menuDetail?.menu_price ? menuDetail.menu_price : "",
      tags: menuDetail && menuDetail.tags ? editTagOptions : "",
      category:
        menuDetail && menuDetail.category_id
          ? {
              value: menuDetail.category_id,
              label: menuDetail.category_name,
            }
          : "",
      preparationTime:
        menuDetail && menuDetail.preparation_time
          ? menuDetail.preparation_time
          : "",
      menuImages: [],
      recommended:
        menuDetail && menuDetail.recommended ? menuDetail.recommended : "0",
    });
    setImgData(
      menuDetail && menuDetail?.image?.length > 0
        ? getEditImages(menuDetail?.image, menuDetail?.menu_image_id)
        : []
    );
    setMenuImages(
      menuDetail && menuDetail?.image?.length > 0 ? menuDetail.image : []
    );
    setMenuImagesPrev(
      menuDetail && menuDetail?.image?.length > 0 ? getprevEditMenuImages() : []
    );
    setAddonList(
      menuDetail && menuDetail?.addons?.length > 0
        ? menuDetail.addons
        : [{ addon_name: "", price: "" }]
    );
    setvariationList(
      menuDetail && menuDetail?.variations?.length > 0
        ? menuDetail.variations
        : [
            {
              var_name: "",
              item: [{ item_name: "", item_price: "0", is_default: "1" }],
            },
          ]
    );
    setCustomOptionList(
      menuDetail && menuDetail?.custom_options?.length > 0
        ? menuDetail.custom_options
        : [
            {
              custom_name: "",
              custom_type: "SINGLE",
              min_select: 0,
              max_select: 0,
              custom_item: [{ item_name: "", item_price: "" }],
            },
          ]
    );
    setPreviousMenuPrice(
      menuDetail && menuDetail?.menu_price ? menuDetail.menu_price : ""
    );
    setPreviousVariationList(
      menuDetail && menuDetail?.variations ? menuDetail.variations : ""
    );
  }, [menuDetail]);

  const imagesHandler = (e) => {
    const { files } = e.target;
    let imgErr = "";

    if (files.length > 0) {
      let teImage = [];
      for (let key in files) {
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            imgErr = "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            imgErr = "Image size should not be greater than 50MB";
            continue;
          }
          teImage.push(files[key]);
        }
      }

      const tempPrev = [...menuImagesPrev];
      for (let i = 0; i < teImage.length; i++) {
        tempPrev.push(URL.createObjectURL(teImage[i]));
      }

      setMenuImages([...menuImages, ...teImage]);
      setMenuImagesPrev([...tempPrev]);
      setImgDataErr(imgErr);
    }
  };

  // Img Modal Functionality
  // const showImgModal = () => {
  // const newArr = imgOptionsForModal.map((imgObj) => {
  //   const isSelected = imgData.some(
  //     (selectedObj) => selectedObj.value === imgObj.value
  //   );
  //   return {
  //     ...imgObj,
  //     selected: isSelected,
  //   };
  // });
  // setImgModal(true);
  // setImgOptionsForModal(newArr);
  // setErrors({
  //   ...errors,
  //   imgErr: "",
  // });
  // };

  // const removeImgHandler = (index) => {
  //   let list = [...imgData];
  //   list.splice(index, 1);
  //   setImgData(list);
  //   setErrors({
  //     ...errors,
  //     imgErr: "",
  //   });
  // };
  //

  const removeImgHandler = (i) => {
    const imgArr = [...menuImages];
    const imgArrPreview = [...menuImagesPrev];
    imgArr.splice(i, 1);
    imgArrPreview.splice(i, 1);

    setMenuImages(imgArr);
    setMenuImagesPrev(imgArrPreview);
  };

  const handleChangeInput = (e) => {
    setValues({ ...values, tags: e });
    setErrors({ ...errors, tags: "" });
  };

  const recommendedChangeHandler = (e) => {
    setValues({
      ...values,
      recommended: e.target.checked ? "1" : "0",
    });
  };

  const imgDataValidation = () => {
    if (menuImages?.length === 0) {
      setImgDataErr("Please select atleast one image!");
    } else {
      return true;
    }
  };

  //handle close price Modal
  // const handleClosePriceModal = () => {
  //   setOpenPriceModal(false);
  // };

  // //handle not Replacing price
  // const doNotReplacePriceHandler = () => {
  //   createMenuItemHandler();
  //   setOpenPriceModal(false);
  // };

  // //handle replace the menu price with variation price
  // const replacePriceHandler = () => {
  //   createMenuItemHandler(true);
  // };

  //handle open price Modal
  // const changeMenuPriceModalHandler = () => {
  //   if (
  //     validate() &&
  //     imgDataValidation() &&
  //     variationValidation(variationList, setVariationErr, setTab) &&
  //     addOnValidation(addonList, setAddonErr, setTab) &&
  //     customsValidation(customOptionList, setCustomOptionErr, setTab) &&
  //     !menuDetail
  //       ? getVariationPriceOrMainPrice(variationList) !== values.price
  //       : (+previousMenuPrice !== +values.price &&
  //           getVariationPriceOrMainPrice(variationList) !== values.price) ||
  //         getVariationPriceOrMainPrice(variationList) !==
  //           getVariationPriceOrMainPrice(previousVariationList)
  //   ) {
  //     setOpenPriceModal(true);
  //   } else {
  //     menuDetail ? createMenuItemHandler() : createMenuItemHandler(true);
  //   }
  // };

  //create Menu Item
  const createMenuItemHandler = async () => {
    if (
      validate() &&
      imgDataValidation() &&
      variationValidation(variationList, setVariationErr, setTab) &&
      addOnValidation(addonList, setAddonErr, setTab) &&
      customsValidation(customOptionList, setCustomOptionErr, setTab)
    ) {
      dispatch(lastTabData(tab));
      const formData = new FormData();
      setUploadLoading(true);
      let tempImage = [];
      for (let i = 0; i < menuImages.length; i++) {
        if (typeof menuImages[i] !== "string") {
          formData.append("file", menuImages[i]);
          let temp = await dispatch(fileUpload(formData));
          if (temp) {
            tempImage.push(temp.payload);
          }
          formData.delete("file");
        } else {
          tempImage.push(menuImages[i]);
        }
      }

      let tagIds = values.tags?.map((item) => item.value);

      const data = {
        menu_name: values.name,
        preparation_time: +values.preparationTime,
        description: values.description,
        tag_id: tagIds,
        image: tempImage,
        category_id: values.category.value,
        menu_price: values.price,
        // menu_price: variationPrice
        //   ? getVariationPriceOrMainPrice(variationList)
        //   : values.price,

        recommended: values.recommended,
      };

      data.addons = createValidAddOnsList(addonList);
      data.variations = createValidVariationsList(variationList);
      data.custom_options = createValidCustomsList(customOptionList);

      if (menuDetail) {
        data.remove_addons = remove_addons;
        data.remove_variations = remove_variations;
        data.remove_customs = remove_customs;
      }

      if (menuDetail) {
        dispatch(
          updateMenuItem({
            id: menuDetail?.id,
            data: data,
            cb: (err, res) => {
              if (err) {
                console.log("err", err);
              } else {
                // dispatch(getMenuItemById({ id: id }));
                dispatch(getMenuItemById({ id: menuDetail?.id }));
                toast.success(t("toast.update_menu_item"), {
                  autoClose: 3000,
                });
              }
            },
          })
        );
        setUploadLoading(false);
      } else {
        dispatch(
          createMenuItem({ data, navigate, toast: t("toast.create_menu_item") })
        );
        setUploadLoading(false);
      }
    }
  };

  const changeTabHandler = (e) => {
    if (tab == "general") {
      if (validate() && imgDataValidation()) {
        setTab(e.target.value);
      }
    } else {
      setTab(e.target.value);
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {menuDetail
            ? `${t("table.menu")} > ${t("common.edit")}`
            : `${t("table.menu")} > ${t("common.create")}`}
        </h2>
        <button
          onClick={() => navigate(-1)}
          className="comman_btn ml-auto secondary_btn"
        >
          {t("common.back")}
        </button>
      </div>

      <div className="project_edit_main_content oc_menu_item_main_content">
        {uploadLoading ? (
          <Loader />
        ) : (
          <>
            <div className="create_from_row">
              <div className="tab span_2" onChange={(e) => changeTabHandler(e)}>
                <input
                  label={t("common.general")}
                  type="radio"
                  id="CREDITED"
                  name="forAll"
                  value={"general"}
                  checked={tab === "general"}
                  readOnly
                />
                <input
                  label={t("form.variations")}
                  type="radio"
                  id="DEBITED"
                  name="forAll"
                  value={"variations"}
                  checked={tab === "variations"}
                  readOnly
                />
                <input
                  label={t("form.add-ons")}
                  type="radio"
                  id="DEBITED"
                  name="forAll"
                  value={"addons"}
                  checked={tab === "addons"}
                  readOnly
                />

                <input
                  label={t("form.custom_options")}
                  type="radio"
                  id="DEBITED"
                  name="forAll"
                  value={"customs"}
                  checked={tab === "customs"}
                  readOnly
                />
              </div>
              {tab === "general" ? (
                <>
                  <div className="oc_general_create_from_row">
                    <div className="login_input_row mb-0">
                      <label className="required">{t("form.category")}</label>
                      <Select
                        placeholder={t("form.category_place")}
                        value={values.category}
                        className="basic-multi-select upper_select_box"
                        classNamePrefix="select"
                        name="category"
                        options={categoryOptions}
                        onChange={(e) => handleInputChange(e, "category")}
                      />
                      {errors.category && (
                        <span className="err_text">{errors.category}</span>
                      )}
                    </div>
                    <div className="project_edit_detail_column_content">
                      <div className="project_edit_detail_divider">
                        <Input
                          className="create_from_input_content null"
                          labelClassName="create_from_label required"
                          inputClassName="create_from_input"
                          errorClassName="err_text"
                          type="text"
                          placeholder={t("placeholder.enter_item_name")}
                          label={t("filter.search_name")}
                          id="name"
                          name="name"
                          value={values.name}
                          onChange={handleInputChange}
                          error={errors.name || ""}
                        />
                      </div>
                    </div>

                    <div className="login_input_row mb-0 multiple_image_select_input_container span_2">
                      <div className="menu_img_title_btn_container">
                        <h2 className="create_from_label required">
                          {t("table.menu_images")}
                        </h2>
                        {/* <button
                      className="menu_images_add_btn"
                      onClick={() => showImgModal()}
                    >
                      {t("common.add") + " " + t("common.images")}
                    </button> */}
                        <div className="announcement_page-view_input-container">
                          <label
                            className="menu_images_add_btn"
                            htmlFor="menu-image-input"
                          >
                            {t("common.add") + " " + t("common.images")}
                          </label>
                          <Input
                            id="menu-image-input"
                            type="file"
                            multiple={true}
                            accept="image/*"
                            name="images"
                            value=""
                            onChange={imagesHandler}
                            className="oc-restaurant-image-upload-menu-image-modal-input"
                          />
                        </div>
                      </div>
                      {menuImagesPrev?.length !== 0 && (
                        <div className="menu_img_selected_container">
                          <div className="menu_img_selected_images_content view_img_container">
                            {menuImagesPrev?.map((item, i) => {
                              return (
                                <div
                                  className="menu_img_selected_image_preview"
                                  key={i}
                                >
                                  <img
                                    className="menu_img_selected_single_image"
                                    src={item}
                                    alt="menu_image"
                                  />
                                  <button
                                    className="selected_single_image_remove_btn"
                                    onClick={() => removeImgHandler(i)}
                                  >
                                    {closeIcon}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {imgDataErr && (
                        <span className="err_text">{imgDataErr}</span>
                      )}
                    </div>

                    <TextArea
                      className="create_from_input_content col_span_2"
                      labelClassName="create_from_label required"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      type="text"
                      placeholder={t("announcement.description_place")}
                      label={t("announcement.description")}
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleInputChange}
                      error={errors.description || ""}
                      rows={2}
                      maxLength={300}
                    />

                    <div className="login_input_row mb-0">
                      <label className="create_from_label required">
                        {t("form.tag_selection")}
                      </label>
                      <Select
                        placeholder="Select Tag"
                        className="basic-multi-select lower_select_box"
                        classNamePrefix="select"
                        name="tags"
                        options={tagOptions}
                        isMulti
                        value={values.tags}
                        onChange={(e) => handleChangeInput(e)}
                      />
                      {errors.tags && (
                        <span className="err_text">{errors.tags}</span>
                      )}
                    </div>

                    <Input
                      className="create_from_input_content null"
                      labelClassName="create_from_label required"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      type="text"
                      placeholder={"Enter preparation time"}
                      label={
                        t("table.preparation_time") +
                        " " +
                        t("table.in_minutes")
                      }
                      id="preparationTime"
                      name="preparationTime"
                      value={values.preparationTime}
                      onChange={handleInputChange}
                      error={errors.preparationTime || ""}
                    />
                    <Input
                      className="create_from_input_content null"
                      labelClassName="create_from_label required"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      type="text"
                      placeholder={t("form.enter_item_price")}
                      label={t("form.price")}
                      id="price"
                      name="price"
                      value={values.price}
                      onChange={handleInputChange}
                      error={errors.price || ""}
                    />
                    <div>
                      <label className="create_from_label undefined">
                        {t("form.recommended")}
                      </label>
                      <div className="custom_option_type_radio_row">
                        <Input
                          className="create_from_radio_checkbox_contentainer"
                          labelClassName={`create_from_radio_label_role`}
                          inputClassName="create_from_radio_checkbox"
                          type="checkbox"
                          label={t("form.is_recommended")}
                          name={"recommended"}
                          value={values.recommended}
                          onChange={(e) => recommendedChangeHandler(e)}
                          checked={values.recommended === "1"}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : tab === "variations" ? (
                <VariationsInput
                  variationList={variationList}
                  setvariationList={setvariationList}
                  variationErr={variationErr}
                  setVariationErr={setVariationErr}
                  setRemove_variations={setRemove_variations}
                  remove_variations={remove_variations}
                  menuDetail={menuDetail}
                />
              ) : tab === "addons" ? (
                <AddonsInput
                  addonList={addonList}
                  setAddonList={setAddonList}
                  addonErr={addonErr}
                  setAddonErr={setAddonErr}
                  remove_addons={remove_addons}
                  setRemove_addons={setRemove_addons}
                  menuDetail={menuDetail}
                />
              ) : (
                <CustomsInput
                  customOptionList={customOptionList}
                  setCustomOptionList={setCustomOptionList}
                  customOptionErr={customOptionErr}
                  setCustomOptionErr={setCustomOptionErr}
                  remove_customs={remove_customs}
                  setRemove_customs={setRemove_customs}
                  menuDetail={menuDetail}
                />
              )}
            </div>
            <Button
              className="project_submit_bottom_btn center_back_btn"
              buttonClassName="comman_btn"
              onClick={() => createMenuItemHandler()}
              // onClick={
              //   createValidVariationsList(variationList)?.length === 0
              //     ? () => createMenuItemHandler()
              //     : changeMenuPriceModalHandler
              // }
              text={
                menuDetail ? `${t("common.save")}` : `${t("common.create")}`
              }
            />
          </>
        )}
      </div>
      {/* {imgModal && (
        <MultiImgSelectModal
          imgOptionsForModal={imgOptionsForModal}
          imgModal={imgModal}
          setImgOptionsForModal={setImgOptionsForModal}
          setImgModal={setImgModal}
          setImgData={setImgData}
          setErrors={setErrors}
          errors={errors}
        />
      )} */}
      {/* <SetVarPriceInMainModal
        openPriceModal={openPriceModal}
        setOpenPriceModal={setOpenPriceModal}
        handleClose={handleClosePriceModal}
        doNotReplacePriceHandler={doNotReplacePriceHandler}
        replacePriceHandler={replacePriceHandler}
      /> */}
    </>
  );
};

export default CreateMenu;

import React from "react";
import { useDispatch } from "react-redux";
import { createMenuItem, getMenuItemList } from "../../store/slice/menuSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../component/Common/Loader";

const CopyMenuItem = ({
  openCopyItemModal,
  setOpenCopyItemModal,
  copiedMenuData,
  setCopiedMenuData,
  handleCloseCopyModal,
  uploadLoading,
  setUploadLoading,
  categoryFilter,
}) => {
  const showHideClassName = openCopyItemModal && "user_detail_modal_show";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const getArrayWithoutIds = (arrWithId, arrayType) => {
    var arr = JSON.parse(JSON.stringify(arrWithId));
    for (let i = 0; i < arrWithId.length; i++) {
      delete arr[i].id;
      if (arrayType === "customs") {
        for (let j = 0; j < arr[i].custom_item.length; j++) {
          delete arr[i].custom_item[j].id;
        }
      } else if (arrayType === "variations") {
        for (let j = 0; j < arr[i].item.length; j++) {
          delete arr[i].item[j].id;
        }
      }
    }
    return arr;
  };

  const copyItem = () => {
    const data = {
      order_number: 0,
      menu_name: copiedMenuData.menu_name,
      preparation_time: +copiedMenuData.preparation_time,
      description: copiedMenuData.description,
      tag_id: copiedMenuData.tag_id,
      image: copiedMenuData.image,
      category_id: copiedMenuData.category_id,
      menu_price: +copiedMenuData.menu_price,
      recommended: copiedMenuData.recommended,
      addons: getArrayWithoutIds(copiedMenuData.addons, "addons"),
      variations: getArrayWithoutIds(copiedMenuData.variations, "variations"),
      custom_options: getArrayWithoutIds(
        copiedMenuData.custom_options,
        "customs"
      ),
    };

    dispatch(
      createMenuItem({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getMenuItemList({
                category_name: !categoryFilter
                  ? ""
                  : categoryFilter && categoryFilter === "All"
                  ? ""
                  : categoryFilter,
              })
            );
            setOpenCopyItemModal(false);
            setCopiedMenuData("");
            toast.success(t("toast.copy_menu_item"), {
              autoClose: 3000,
            });
          }
        },
      })
    );
  };

  return (
    <>
      {uploadLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`user_detail_modal_bg ${showHideClassName}`}
            onClick={handleCloseCopyModal}
          ></div>
          <section
            className={`user_detail_modal_main delete_feedback_modal ${showHideClassName}`}
          >
            <div className="inquiry_complaint_detail_section">
              <h2 className="announcement_page-view-modal">
                {t("common.duplicate") + " " + t("dashboard.menu_items")}
              </h2>
              <h4 className="announcement_page-view-modal delete_feedback_subheading">
                {t("toast.copy_menu_item_modal")}
              </h4>
              <div className="delete_feedback_btn-container">
                <button
                  type="button"
                  className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
                  onClick={handleCloseCopyModal}
                >
                  {t("common.cancel")}
                </button>

                <button
                  type="button"
                  className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn ml-20"
                  onClick={copyItem}
                >
                  {t("common.duplicate")}
                </button>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default CopyMenuItem;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import {
  editIcon,
  deleteIcon,
  viewIcon,
  checkIcon,
  crossIcon,
} from "../../icons";
import moment from "moment/moment";
import DeletePromoCode from "./DeletePromoCode";
import {
  getPromoCodeList,
  updatePromoCode,
} from "../../store/slice/promoCodeSlice";
import Loader from "../../component/Common/Loader";
import { defaultTimeZone } from "../../helpers/commonFunction";
import Pagination from "../../component/Pagination/Pagination";
import { useRef } from "react";
import ViewPromoCode from "./ViewPromoCode";
import { toast } from "react-toastify";

const PromoCode = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;

  const promoCodeList = useSelector(
    (state) => state.promoCode.PromoCodeList?.data
  );

  const promoCodeCount = useSelector(
    (state) => state.promoCode.PromoCodeList?.count
  );
  const promoLoader = useSelector((state) => state.promoCode.loader);

  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [pageN, setPageN] = useState(page_number - 1);

  const [viewModal, setViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState("");

  useEffect(() => {
    if (ref.current) {
      dispatch(getPromoCodeList({ start: (page_number - 1) * 10, limit: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getPromoCodeList({ start: (page_number - 1) * 10, limit: 10 }));
    ref.current = true;
  }, []);

  const deletePromoCodeHandler = (item) => {
    setDeleteModal(true);
    setDeleteId(item.id);
  };

  const handleCloseHandler = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };
  const viewPromoCodeModal = (item) => {
    setViewModal(true);
    setViewModalData(item);
  };

  const closeViewPromoModal = () => {
    setViewModal(false);
    setViewModalData("");
  };

  const currency = "฿";
  const percentage = "%";

  const activePromoCodeHandler = (item) => {
    const data = {
      active_status: item.active_status === "1" ? "0" : "1",
    };
    dispatch(
      updatePromoCode({
        data,
        id: item.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success(
              item.active_status === "0"
                ? t("toast.active_promo")
                : t("toast.deactive_promo"),
              {
                autoClose: 2000,
              }
            );
            dispatch(
              getPromoCodeList({ start: (page_number - 1) * 10, limit: 10 })
            );
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("table.promo_code")}</h2>
          <div className="creat_edit_project_btn_row">
            <Link
              to={routes.createPromoCode}
              state={{ pageNumber: page_number }}
              className="comman_btn ml-auto"
            >
              {t("common.create")}
            </Link>
          </div>
        </div>

        {promoLoader ? (
          <Loader />
        ) : promoCodeList?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <div className="custom_data_table_content">
            <table className="custom_data_table">
              <thead className="custom_data_table_head">
                <tr>
                  <th className="custom_data_table_heading">
                    {t("table.promo_code")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.discount_type")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.discount")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.max_discount")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.max_usage")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.max_usage_per_user")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.start_date")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.end_date")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.is_active")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.createdAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.updatedAt")}
                  </th>
                  <th className="custom_data_table_heading">
                    {t("table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="custom_data_table_body">
                {promoCodeList?.map((item, index) => {
                  return (
                    <tr className="custom_data_table_row" key={item.id}>
                      <td className="custom_data_table_item table_item">
                        {item.code}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.discount_type}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.discount}{" "}
                        {item.discount_type == "PERCENTAGE"
                          ? percentage
                          : currency}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.max_discount} {currency}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.max_usage}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.max_usage_per_user}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.start_date
                          ? moment
                              .utc(item.start_date, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.end_date
                          ? moment
                              .utc(item.end_date, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.active_status === "1" ? checkIcon : crossIcon}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.created_at
                          ? moment
                              .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.updated_at
                          ? moment
                              .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>

                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              item.active_status === "1" ? "red" : "green"
                            }`}
                            onClick={() => activePromoCodeHandler(item)}
                          >
                            {item.active_status === "0"
                              ? t("table.active")
                              : t("table.inactive")}
                          </button>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => viewPromoCodeModal(item)}
                            >
                              {viewIcon}
                            </button>

                            <span className="tooltiptext">
                              {t("common.view")}
                            </span>
                          </div>
                          <div className="tooltip">
                            <Link
                              to={`${routes.promoCode}/edit/${item.id}`}
                              state={{ promo: item, pageNumber: page_number }}
                              className="custom_data_table_view_edit_item_btn"
                            >
                              {editIcon}
                            </Link>
                            <span className="tooltiptext">
                              {t("common.edit")}
                            </span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => deletePromoCodeHandler(item)}
                            >
                              {deleteIcon}
                            </button>
                            <span className="tooltiptext">
                              {t("common.delete")}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {promoCodeList?.length > 0 && (
          <Pagination
            totalRecords={promoCodeCount}
            onPageChange={onPageChange}
          />
        )}

        <DeletePromoCode
          deleteId={deleteId}
          setDeleteId={setDeleteId}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleClose={handleCloseHandler}
          pageNumber={page_number}
          totalCount={promoCodeCount}
        />
        <ViewPromoCode
          show={viewModal}
          handleClose={closeViewPromoModal}
          viewPromoCodeData={viewModalData}
        />
      </div>
    </>
  );
};

export default PromoCode;

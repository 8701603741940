import moment from "moment";

export const dayName = [
  "",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dateFunction = (time) => {
  return moment(`2016-12-08 ${time}`, "YYYY-MM-DD h:m:s A").format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

// Create PromoCode
export const createPromoCode = createAsyncThunk(
  "createPromoCode",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity-restaurant/promo-code`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.promoCode}/page/1`);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// Update Promocode
export const updatePromoCode = createAsyncThunk(
  "updatePromoCode",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity-restaurant/promo-code/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.promoCode}/page/1`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// Get PromoCode List
export const getPromoCodeList = createAsyncThunk(
  "getPromoCodeList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity-restaurant/promo-code?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  PromoCodeList: [],
  loader: false,
  createPromoCodeComponent: "",
  updatePromoCodeComponent: "",
};
const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //Menu List
    builder.addCase(getPromoCodeList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPromoCodeList.fulfilled, (state, action) => {
      state.loader = false;
      state.PromoCodeList = action.payload;
    });
    builder.addCase(getPromoCodeList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //Add Menu
    builder.addCase(createPromoCode.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createPromoCode.fulfilled, (state, action) => {
      state.loader = false;
      state.createPromoCodeComponent = action.payload;
    });
    builder.addCase(createPromoCode.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //Update Menu
    builder.addCase(updatePromoCode.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updatePromoCode.fulfilled, (state, action) => {
      state.loader = false;
      state.updatePromoCodeComponent = action.payload;
    });
    builder.addCase(updatePromoCode.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export default promoCodeSlice.reducer;

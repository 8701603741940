import React from "react";
import { useTranslation } from "react-i18next";
import { defaultTimeZone } from "../../helpers/commonFunction";
import moment from "moment";

const ViewPromoCode = ({ show, handleClose, viewPromoCodeData }) => {
  const { t } = useTranslation("common");
  const showHideClassName = show && "user_detail_modal_show";

  const currency = "฿";
  const percentage = "%";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <h2 className="announcement_page-view-modal">
            {`${t("table.promo_code")} > ${t("common.view")}`}
          </h2>
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body view-modal_container">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.promo_code")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.code}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.discount_type")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.discount_type}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.discount")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.discount}{" "}
                  {viewPromoCodeData.discount_type == "PERCENTAGE"
                    ? percentage
                    : currency}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.max_discount")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.max_discount} {currency}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.max_usage")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.max_usage}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.max_usage_per_user")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.max_usage_per_user}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.start_date")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.start_date
                    ? moment
                        .utc(viewPromoCodeData.start_date, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.end_date")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.end_date
                    ? moment
                        .utc(viewPromoCodeData.end_date, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Minimum Order Amount
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.minimum_order} {currency}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.createdAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.created_at
                    ? moment
                        .utc(viewPromoCodeData.created_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  {t("table.updatedAt")}
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewPromoCodeData.updated_at
                    ? moment
                        .utc(viewPromoCodeData.updated_at, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn secondary_btn"
          >
            {t("common.close")}
          </button>
        </div>
      </section>
    </>
  );
};

export default ViewPromoCode;

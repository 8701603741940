import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import { eyeIcon, hideEyeIcon } from "../../icons";
import faviconWhite from "../../images/onecitylogo/FaviconWhite.png";
import { confirmPasswordAdmin } from "../../store/slice/userSlice";

const ForgotPasswordPage = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFValues = {
    password: "",
    inputTypePassword: "password",
    confirm_password: "",
    inputTypeConfirmPassword: "password",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("password" in fieldValues) {
      if (!fieldValues.password) {
        temp.password = "Password is required!";
      } else {
        temp.password =
          fieldValues.password.length > 5
            ? ""
            : "Password must contain at least 6 characters!";
      }
    }
    if ("confirm_password" in fieldValues) {
      if (fieldValues.password !== fieldValues.confirm_password) {
        temp.confirm_password = "The password confirmation does not match!";
      } else {
        temp.confirm_password = fieldValues.confirm_password
          ? ""
          : "Confirm password is required!";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (values.confirm_password) {
      validate(values);
    }
  }, [values.password, values.confirm_password]);

  const showPasswordHandler = (e) => {
    e.preventDefault();
    if (values.inputTypePassword === "password") {
      setValues({
        ...values,
        inputTypePassword: "text",
      });
    } else {
      setValues({
        ...values,
        inputTypePassword: "password",
      });
    }
  };

  const showConfirmPasswordHandler = (e) => {
    e.preventDefault();
    if (values.inputTypeConfirmPassword === "password") {
      setValues({
        ...values,
        inputTypeConfirmPassword: "text",
      });
    } else {
      setValues({
        ...values,
        inputTypeConfirmPassword: "password",
      });
    }
  };

  const resetPasswordHandler = (e) => {
    e.preventDefault();

    if (validate()) {
      const data = {
        password: values.password,
      };

      dispatch(confirmPasswordAdmin({ data, navigate, token }));
    }
  };

  return (
    <section className="login_section">
      <div className="login_content">
        <div className="login_top_content">
          <h2 className="zu_login_form_heading">Reset Password</h2>
        </div>
        <div className="login_input_logo_content">
          <div className="login_logo">
            <img src={faviconWhite} alt="company_logo" />
          </div>
          <form className="login_input_content">
            <Input
              className="login_input_row null"
              errorClassName="err_text"
              type={values.inputTypePassword}
              placeholder="Password"
              label="Password"
              id="password"
              name="password"
              onChange={handleInputChange}
              value={values.password}
              error={errors?.password || ""}
              eyeIcon={
                values.inputTypePassword === "password" ? eyeIcon : hideEyeIcon
              }
              eyeIconHandler={showPasswordHandler}
            />
            <Input
              className="login_input_row null"
              errorClassName="err_text"
              type={values.inputTypeConfirmPassword}
              placeholder="Confirm Password"
              label="Confirm Password"
              id="confirm_password"
              name="confirm_password"
              onChange={handleInputChange}
              value={values.confirm_password}
              error={errors?.confirm_password || ""}
              eyeIcon={
                values.inputTypeConfirmPassword === "password"
                  ? eyeIcon
                  : hideEyeIcon
              }
              eyeIconHandler={showConfirmPasswordHandler}
            />
            <Button
              type="submit"
              buttonClassName="login_btn"
              onClick={resetPasswordHandler}
              text="Reset"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordPage;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

// Create MenuImg
export const createMenuImg = createAsyncThunk(
  "createMenuImg",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/onecity-restaurant/menu-image`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(`${routes.category}`);
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      // console.log("error", error.response);
    }
  }
);

export const updateMenuImg = createAsyncThunk(
  "updateMenuImg",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity-restaurant/menu-image/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.error(data.toast, {
          autoClose: 3000,
        });
        // data.navigate(`${routes.category}`);
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      // console.log("error", error.response);
    }
  }
);

export const getMenuImg = createAsyncThunk(
  "getMenuImg",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity-restaurant/menu-image?start=${data.start || 0}&limit=${
          data.limit || 1000000000
        }`,
        authHeaders()
      );
      return { data: response.data, count: response.headers["x-total-count"] };
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  menuImgList: [],
  createMenuImage: "",
  updateMenuImage: "",
  loader: false,
  error: "",
  user: "",
};
const menuImagesSlice = createSlice({
  name: "menuImg",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //get Menu Image
    builder.addCase(getMenuImg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMenuImg.fulfilled, (state, action) => {
      state.loader = false;
      state.menuImgList = action.payload;
    });
    builder.addCase(getMenuImg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //create menu Image
    builder.addCase(createMenuImg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createMenuImg.fulfilled, (state, action) => {
      state.loader = false;
      state.createMenuImage = action.payload;
    });
    builder.addCase(createMenuImg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //update menu Image
    builder.addCase(updateMenuImg.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateMenuImg.fulfilled, (state, action) => {
      state.loader = false;
      state.updateMenuImage = action.payload;
    });
    builder.addCase(updateMenuImg.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export default menuImagesSlice.reducer;

import React, { useState } from "react";
import { Outlet } from "react-router";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "../../css/component.css";
import Footer from "./Footer";
import { io } from "socket.io-client";
import { useRef } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Layout = () => {
  const socket = useRef();

  const [count, setCount] = useState(0);
  const [cancelOrderCount, setCancelOrderCount] = useState(0);

  useEffect(() => {
    // console.log("in first uf");
    // socket.connect();
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      query: {
        token:
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).token,
      },
    });

    socket.current.on("connection", function (socket) {
      // Connection now authenticated to receive further events
      // console.log("socket connection done");
    });

    socket.current.on("order", (arg) => {
      // console.log("ORDER ====>> NEW", arg);
      const isCancel = arg.is_cancel ? true : false;
      // console.log("isCancel", isCancel);
      if (arg.count !== 0 || (arg.count === 0 && isCancel)) {
        setCount(arg.count);
        if (!isCancel) {
          toast.warning("New Order Received!", {
            autoClose: 2000,
          });
        }
      }
    });

    socket.current.on("order_cancel", (arg) => {
      // console.log("ORDER ====>> CANCEL", arg);
      if (arg.cancel_count !== 0) {
        setCancelOrderCount(arg.cancel_count);
        toast.error("Order has been cancelled!", {
          autoClose: 2000,
        });
      }
    });

    return () => {
      socket.current.off("connection");
      socket.current.off("order");
      socket.current.off("order_cancel");
      socket.current.disconnect();
    };
  }, []);

  return (
    <div className="Layout">
      <input type="checkbox" id="sidebar_toggel" />
      <Sidebar count={count} setCount={setCount} />
      <main className="main_content">
        <Navbar />
        <Outlet
          context={{
            count: count,
            setCount: setCount,
            setCancelOrderCount: setCancelOrderCount,
            cancelOrderCount: cancelOrderCount,
          }}
        />
        <Footer />
      </main>
    </div>
  );
};

export default Layout;

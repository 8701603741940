import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../component/Common/Input";
import { dateFunction } from "../../component/Common/PageComponent/DirectoryComponents";
import { withoutMetadata } from "../../component/Common/PageComponent/LoginPageComponents";
import { useForm } from "../../hooks/useForm";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import Button from "../../component/Common/Button";
import {
  getRestaurantDetail,
  updateRestaurant,
} from "../../store/slice/userSlice";
import TextArea from "../../component/Common/TextArea";
import { addIcon, editIcon } from "../../icons";
import { imagePreviewProps } from "../../helpers/commonFunction";
import { useState } from "react";
import { fileUpload } from "../../store/slice/fileUploadSlice";
import Loader from "../../component/Common/Loader";
import CommonDatePicker from "../../component/Common/DatePicker";

const Profile = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("common");

  const restaurant = useSelector((state) => state.user.restaurantDetail);
  const loading = useSelector((state) => state.user.loader);

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    dispatch(getRestaurantDetail());
  }, []);

  const initialFValues = {
    icon: restaurant && restaurant?.logo ? restaurant?.logo : "",
    icon_preview:
      restaurant && restaurant?.logo
        ? `${process.env.REACT_APP_FILE_BASE_URL}/${restaurant?.logo}`
        : "",
    restaurant_name: restaurant ? restaurant?.restaurant_name : "",
    owner_name: restaurant ? restaurant?.owner_name : "",

    phone_number: restaurant
      ? "+" + restaurant.country_code + restaurant.phone_number
      : "",
    email: restaurant ? restaurant?.email : "",
    price_range: restaurant ? restaurant?.price_range : "",
    address: restaurant ? restaurant?.address : "",
    pickup_time: restaurant ? restaurant?.pickup_time : "",
  };

  const dayName = [
    "",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const priceRangeOptions = [
    {
      value: "$",
      label: "Low",
    },
    {
      value: "$$",
      label: "Medium",
    },
    {
      value: "$$$",
      label: "High",
    },
  ];

  useEffect(() => {
    setValues({
      ...values,
      icon: restaurant ? restaurant?.logo : "",
      icon_preview:
        restaurant && restaurant?.logo
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${restaurant?.logo}`
          : "",
      restaurant_name: restaurant ? restaurant?.restaurant_name : "",
      owner_name: restaurant ? restaurant?.owner_name : "",
      phone_number: restaurant
        ? "+" + restaurant.country_code + restaurant.phone_number
        : "",
      email: restaurant ? restaurant?.email : "",
      price_range: restaurant
        ? restaurant.price_range
          ? {
              value: restaurant.price_range,
              label:
                (restaurant.price_range === "$" && "Low") ||
                (restaurant.price_range === "$$" && "Medium") ||
                (restaurant.price_range === "$$$" && "High"),
            }
          : ""
        : "",
      address: restaurant ? restaurant?.address : "",
      pickup_time: restaurant ? restaurant?.pickup_time : "",
      opening_times:
        restaurant?.opening_times?.length > 0
          ? [
              {
                name: 1,
                id: restaurant.opening_times[0].id,
                weekday: 1,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[0].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[0].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[0].is_active
                  : "0",
              },
              {
                name: 2,
                id: restaurant.opening_times[1].id,
                weekday: 2,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[1].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[1].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[1].is_active
                  : "0",
              },
              {
                name: 3,
                id: restaurant.opening_times[2].id,
                weekday: 3,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[2].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[2].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[2].is_active
                  : "0",
              },
              {
                name: 4,
                id: restaurant.opening_times[3].id,
                weekday: 4,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[3].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[3].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[3].is_active
                  : "0",
              },
              {
                name: 5,
                id: restaurant.opening_times[4].id,
                weekday: 5,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[4].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[4].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[4].is_active
                  : "0",
              },
              {
                name: 6,
                id: restaurant.opening_times[5].id,
                weekday: 6,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[5].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[5].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[5].is_active
                  : "0",
              },
              {
                name: 0,
                id: restaurant.opening_times[6].id,
                weekday: 7,
                start_hour: new Date(
                  dateFunction(restaurant.opening_times[6].start_time)
                ),
                end_hour: new Date(
                  dateFunction(restaurant.opening_times[6].end_time)
                ),
                closed: restaurant
                  ? restaurant.opening_times[6].is_active
                  : "0",
              },
            ]
          : [
              {
                name: 1,
                weekday: 1,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
              {
                name: 2,
                weekday: 2,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
              {
                name: 3,
                weekday: 3,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
              {
                name: 4,
                weekday: 4,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
              {
                name: 5,
                weekday: 5,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
              {
                name: 6,
                weekday: 6,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
              {
                name: 0,
                weekday: 7,
                start_hour: "",
                end_hour: "",
                closed: "1",
              },
            ],
    });
  }, [restaurant]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("icon" in fieldValues) {
      temp.icon = "";
      if (!fieldValues.icon) {
        temp.icon = t("errors.icon_require");
      }
      if (fieldValues.icon?.size > 5000000) {
        temp.icon = t("errors.icon_size_val");
        setValues({
          ...values,
          icon_preview: "",
          icon: "",
        });
      }
      if (
        fieldValues.icon?.name &&
        !fieldValues.icon.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.icon = t("errors.icon_invalid");
        setValues({
          ...values,
          icon_preview: "",
          icon: "",
        });
      }
    }

    if ("owner_name" in fieldValues) {
      temp.owner_name = fieldValues.owner_name?.trim()
        ? ""
        : t("errors.owner_name");
    }

    if ("price_range" in fieldValues) {
      temp.price_range = fieldValues.price_range ? "" : t("errors.price_range");
    }

    if ("address" in fieldValues) {
      temp.address = fieldValues.address?.trim() ? "" : t("errors.address");
    }

    if ("pickup_time" in fieldValues) {
      temp.pickup_time = fieldValues.pickup_time
        ? ""
        : "Please select pickup time";
    }
    if ("phone_number" in fieldValues) {
      temp.phone_number = fieldValues.phone_number
        ? isValidPhoneNumber(fieldValues.phone_number)
          ? ""
          : t("errors.phone_valid")
        : t("errors.phone_required");
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({ ...values, icon: "", icon_preview: "" });
  };

  const onChangeClose = (e, item) => {
    let temp = [...values.opening_times];
    temp[item.weekday - 1]["closed"] = e.target.checked ? "0" : "1";

    setValues({
      ...values,
      opening_times: temp,
    });
    setErrors({
      ...errors,
      opening_times: "",
    });
  };

  const onChangeStart = (e, item) => {
    let temp = [...values.opening_times];
    temp[item.weekday - 1]["start_hour"] = e;
    setValues({
      ...values,
      opening_times: temp,
    });
    setErrors({
      ...errors,
      opening_times: "",
    });
    if (temp[item.weekday - 1]["weekday"] === 1) {
      setDefaultTimeFromMondayStartTime();
    }
  };

  const setDefaultTimeFromMondayStartTime = () => {
    let temp = [...values.opening_times];
    const mondayStartHour = values.opening_times[0]?.start_hour;

    temp.forEach((item) => {
      if (item.start_hour === "") {
        item.start_hour = mondayStartHour;
      }
    });

    setValues({
      ...values,
      opening_times: temp,
    });
  };

  const onChangeEnd = (e, item) => {
    let temp = [...values.opening_times];
    temp[item.weekday - 1]["end_hour"] = e;
    setValues({
      ...values,
      opening_times: temp,
    });
    setErrors({
      ...errors,
      opening_times: "",
    });
    if (temp[item.weekday - 1]["weekday"] === 1) {
      setDefaultTimeFromMondayEndTime();
    }
  };

  const setDefaultTimeFromMondayEndTime = () => {
    let temp = [...values.opening_times];
    const mondayStartHour = values.opening_times[0]?.end_hour;

    temp.forEach((item) => {
      if (item.end_hour === "") {
        item.end_hour = mondayStartHour;
      }
    });
  };

  const openingValidation = () => {
    let isValid = false;

    values?.opening_times?.filter(
      (item) =>
        item.start_hour == "" &&
        item.end_hour == "" &&
        item.start_hour === item.end_hour &&
        (item.closed === "1" || item.closed === "0")
      // ||
      // (item.start_hour?.getTime() == item.end_hour?.getTime() &&
      //   (item.closed === "1" || item.closed === "0"))
    ).length > 0
      ? setErrors({
          ...errors,
          opening_times: t("errors.timing_error"),
        })
      : (isValid = true);

    return isValid;
  };

  const createRestaurantHandler = async () => {
    if (validate() && openingValidation()) {
      setUploading(true);
      const formData = new FormData();
      let iconImgUrl;
      if (values.icon && typeof values.icon !== "string") {
        formData.append("file", values.icon);
        iconImgUrl = await dispatch(fileUpload(formData));
        formData.delete("file");
      } else {
        iconImgUrl = values.icon;
      }

      setUploading(false);

      const parsedPhone =
        values.phone_number &&
        withoutMetadata(parsePhoneNumber(values.phone_number));

      const data = {
        logo: typeof values.icon !== "string" ? iconImgUrl.payload : iconImgUrl,
        owner_name: values.owner_name,
        country_code: parsedPhone ? parsedPhone?.countryCallingCode : "",
        price_range: values.price_range?.value,
        phone_number: parsedPhone ? parsedPhone?.nationalNumber : "",
        address: values.address,
        pickup_time: +values.pickup_time,
        restaurant_opening_times: values.opening_times?.map((item) => {
          if (!item.start_hour || !item.end_hour) {
            return {
              weekday: item.name,
              start_time: "",
              end_time: "",
              is_active: item.closed,
            };
          } else {
            return {
              id: item.id,
              weekday: item.name,
              start_time: moment(item.start_hour).format("HH:mm"),
              end_time: moment(item.end_hour).format("HH:mm"),
              is_active: item.closed,
            };
          }
        }),
      };
      dispatch(
        updateRestaurant({
          data,
          id: restaurant.id,
          toast: t("toast.update_restaurant"),
        })
      );
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {t("table.profile_edit_head")}
        </h2>
        <div>
          <Link to={routes.changePassword}>
            <button
              className="create_link_btn mr-20"
              // onClick={changePasswordComponentHandler}
            >
              {t("table.change_password")}
            </button>
          </Link>
        </div>
      </div>
      {loading || uploading ? (
        <Loader />
      ) : (
        <div className="project_edit_main_content">
          <div className="create_from_row">
            <div className="project_edit_detail_column_content">
              <div className="project_edit_detail_divider">
                <label className="create_from_label required">
                  {t("form.restaurant_icon")}
                </label>
                <div className="project_edit_feature_input_file">
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    errorClassName="err_text"
                    type="file"
                    id={`icon`}
                    labelOtherProps={
                      <span>{!values.icon ? addIcon : editIcon}</span>
                    }
                    name="icon"
                    accept="image/*"
                    onChange={handleInputChange}
                    onClick={(e) => (e.target.value = null)}
                    imageProps={imagePreviewProps(
                      values.icon,
                      values.icon_preview,
                      imageRemoveHandler
                    )}
                  />
                </div>

                <p className="create_project_review mb-0 block">
                  {t("form.imageValid_mb")}
                </p>
                {errors.icon && <span className="err_text">{errors.icon}</span>}
              </div>
            </div>
            <div className="project_edit_feature_input mt-0">
              <Input
                className="create_from_input_content mt-0 mb-15"
                labelClassName="create_from_label required"
                inputClassName="create_from_input disabled_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.restaurant_place")}
                label={t("form.restaurantName")}
                id="restaurant_name"
                name="restaurant_name"
                value={values.restaurant_name}
                onChange={handleInputChange}
                error={errors.restaurant_name || ""}
                disabled={true}
              />
              <Input
                className="create_from_input_content col_span_2"
                labelClassName="create_from_label required"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="text"
                placeholder={t("form.owner_name_place")}
                label={t("form.owner_name")}
                id="owner_name"
                name="owner_name"
                value={values.owner_name}
                onChange={handleInputChange}
                error={errors.owner_name || ""}
              />
            </div>
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName={`create_from_input disabled_input`}
              errorClassName="err_text"
              type="email"
              placeholder={t("form.emailPlace")}
              label={t("form.email")}
              id="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email || ""}
              disabled={true}
            />
            <div className="login_input_row mb-0">
              <label className="required">{t("form.phoneNumber")}</label>
              <PhoneInput
                placeholder={t("form.phoneNumberPlace")}
                value={values.phone_number}
                defaultCountry="TH"
                onChange={(e) => {
                  setValues({
                    ...values,
                    phone_number: e,
                  });
                  setErrors({ ...errors, phone_number: "" });
                }}
              />
              {errors.phone_number && (
                <span className="err_text">{errors.phone_number}</span>
              )}
            </div>
            <TextArea
              className="create_from_input_content"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("form.enter_restaurant_address")}
              label={t("table.location")}
              id="address"
              name="address"
              value={values.address}
              onChange={handleInputChange}
              error={errors.address || ""}
              rows={2}
            />
            <div className="login_input_row mb-0">
              <label className="required">{t("form.price_range")}</label>
              <Select
                placeholder={t("form.select_price_range")}
                value={values.price_range}
                className="basic-multi-select"
                classNamePrefix="select"
                name="price_range"
                options={priceRangeOptions}
                onChange={(e) => handleInputChange(e, "price_range")}
              />
              {errors.price_range && (
                <span className="err_text">{errors.price_range}</span>
              )}
            </div>

            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label required"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder={t("table.enter_pick_up_time")}
              label="Estimated order pick-up time (In minutes)"
              id="pickup_time"
              name="pickup_time"
              value={values.pickup_time}
              onChange={handleInputChange}
              error={errors.pickup_time || ""}
            />
          </div>
          <div>
            <label className="create_from_nested_label required">
              {t("directory.business_hours")}
            </label>
            <div className="create_from_nested_row">
              {values.opening_times?.map((item, i) => {
                return (
                  <div className="create_from_input_content" key={i}>
                    <label className="create_from_label">
                      {t(`directory.${dayName[item.weekday]}`)}
                    </label>
                    <Input
                      className="create_from_radio_checkbox_contentainer"
                      labelClassName="create_from_radio_label_role"
                      inputClassName="create_from_radio_checkbox"
                      type="checkbox"
                      label={t("common.close")}
                      id={`${item.weekday - 1}`}
                      name="closed"
                      value={values?.opening_times[item.weekday - 1].closed}
                      onChange={(e) => {
                        onChangeClose(e, item);
                      }}
                      checked={
                        values?.opening_times[item.weekday - 1].closed === "0"
                      }
                    />
                    {/* </div> */}
                    <DatePicker
                      className={`create_from_input mb-5 ${
                        item.closed === "0" && "disable_date_picker_input"
                      }`}
                      placeholderText={t("directory.opening_times_place")}
                      selected={
                        item.start_hour
                          ? item.start_hour.getTime() === 1481135400000
                            ? ""
                            : item.start_hour
                          : item.start_hour
                      }
                      onChange={(e) => onChangeStart(e, item)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      disabled={item.closed === "0"}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    <DatePicker
                      className={`create_from_input  ${
                        item.closed === "0" && "disable_date_picker_input"
                      }`}
                      placeholderText={t("directory.closing_times_place")}
                      selected={
                        item.end_hour
                          ? item.end_hour.getTime() === 1481135400000
                            ? ""
                            : item.end_hour
                          : item.end_hour
                      }
                      onChange={(e) => onChangeEnd(e, item)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      disabled={item.closed === "0"}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                );
              })}
              <div></div>
              <span className="err_text width_max_content">
                {errors.opening_times || ""}
              </span>
            </div>
          </div>

          <Button
            className="project_submit_bottom_btn center_back_btn"
            buttonClassName="comman_btn"
            onClick={createRestaurantHandler}
            text={t("common.update")}
          />
        </div>
      )}
    </>
  );
};

export default Profile;

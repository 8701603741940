import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  checkIcon,
  copyIcon,
  crossIcon,
  deleteIcon,
  dragDropIcon,
  editIcon,
  viewIcon,
} from "../../../../icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { defaultTimeZone } from "../../../../helpers/commonFunction";
import CopyMenuItem from "../../../../pages/Menu/CopyMenuItem";
import { useDispatch } from "react-redux";
import { clearLastTabData } from "../../../../store/slice/menuSlice";

const MenuListTable = ({
  menuItems,
  viewItemHandler,
  deleteMenuItem,
  copyMenuItem,
  activeMenuItemHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const currency = "฿";

  const editPageHandler = (item) => {
    navigate(`/edit-menu/${item.id}`);
    dispatch(clearLastTabData());
  };

  return (
    <>
      <div className="custom_data_table_content">
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              <th className="custom_data_table_heading"></th>
              <th className="custom_data_table_heading">
                {t("common.category_name")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.menu_name")}
              </th>

              <th className="custom_data_table_heading">
                {t("table.preparation_time")}
              </th>
              <th className="custom_data_table_heading">{t("form.price")}</th>
              <th className="custom_data_table_heading">
                {t("table.is_available")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.createdAt")}
              </th>
              <th className="custom_data_table_heading">
                {t("table.updatedAt")}
              </th>
              <th className="custom_data_table_heading">{t("table.action")}</th>
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {menuItems?.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={`${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      className="custom_data_table_row"
                      key={item.id}
                      provided={provided}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {/* <tr className="custom_data_table_row" key={item.id}> */}
                      <td className="custom_data_table_item table_item">
                        {dragDropIcon}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.category_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.menu_name}
                      </td>

                      <td className="custom_data_table_item table_item">
                        {item.preparation_time
                          ? item.preparation_time + " Minutes"
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.menu_price
                          ? item.menu_price + " " + currency
                          : "-"}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.active_status === "1" ? checkIcon : crossIcon}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.created_at &&
                          moment
                            .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                            .clone()
                            .tz(defaultTimeZone)
                            .format("DD/MM/YYYY, hh:mm A")}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.updated_at
                          ? moment
                              .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")
                          : "-"}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <button
                            className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                              item.active_status === "1" ? "red" : "green"
                            }`}
                            onClick={() =>
                              activeMenuItemHandler(item, "active")
                            }
                          >
                            {item.active_status === "0"
                              ? t("table.available")
                              : t("table.un_available")}
                          </button>

                          <div className="tooltip">
                            <Link
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => copyMenuItem(item)}
                            >
                              {copyIcon}
                            </Link>
                            <span className="tooltiptext">
                              {t("common.duplicate") + " " + t("common.item")}
                            </span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => viewItemHandler(item)}
                            >
                              {viewIcon}
                            </button>

                            <span className="tooltiptext">
                              {t("common.view")}
                            </span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              // to={`/edit-menu/${item.id}`}
                              onClick={() => editPageHandler(item)}
                            >
                              {editIcon}
                            </button>

                            <span className="tooltiptext">
                              {t("common.edit")}
                            </span>
                          </div>
                          <div className="tooltip">
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={() => deleteMenuItem(item)}
                            >
                              {deleteIcon}
                            </button>
                            <span className="tooltiptext">
                              {t("common.delete")}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MenuListTable;

import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ImageModal from "./ImageModal";
import {
  getResImg,
  updateResImg,
} from "../../store/slice/restaurantImagesSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteResImgModal from "./DeleteResImgModal";
import Loader from "../../component/Common/Loader";
import { useRef } from "react";
import Button from "../../component/Common/Button";
import { toast } from "react-toastify";
import SortableImages from "./SortableImages";
import { DndContext, closestCorners } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";

const RestaurantImages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const ref = useRef(false);

  // Get reducers data
  const restaurantImgs = useSelector((state) => state.resImg.resImgList?.data);
  const loading = useSelector((state) => state.resImg.loader);

  const [addModal, setAddModal] = useState(false);
  const [imgErr, setImgErr] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [ordering, setOrdering] = useState(false);
  const [checkValue, setCheckValue] = useState([]);
  const [clickedSelectAll, setClickedSelectAll] = useState(false);

  const [imgSrc, setImgSrc] = useState("");
  const [imgZoom, setImgZoom] = useState(false);
  const [resImages, setResImages] = useState([]);
  const [dragCounter, setDragCounter] = React.useState(0);

  useEffect(() => {
    if (restaurantImgs) {
      setResImages(restaurantImgs);
    }
  }, [restaurantImgs]);

  useEffect(() => {
    dispatch(getResImg({ start: 0, limit: 1000000000 }));
  }, []);

  const handleDeleteCloseHandler = () => {
    setDeleteModal(false);
    setImgErr("");
  };

  // For save updated image orders
  const saveDragResImgHandler = () => {
    setOrdering(true);
    for (let i = 0; i < resImages.length; i++) {
      let arrId = [];
      arrId.push(resImages[i].id);
      const data = {
        order_number: i + 1,
        id: arrId,
      };

      dispatch(
        updateResImg({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              if (i === resImages.length - 1) {
                dispatch(getResImg({ start: 0, limit: 1000000000 }));
                toast.success(t("toast.re-arrange_res_img"), {
                  autoClose: 2000,
                });
                ref.current = false;
                setOrdering(false);
              }
            }
          },
        })
      );
    }
  };

  // onChange handler of check-box on image
  const checkboxOnImgOnChangeHandler = (e, checkItem) => {
    let tempCheckedValue = [...checkValue];
    if (tempCheckedValue?.includes(checkItem.id)) {
      let index = tempCheckedValue.findIndex((obj) => obj === checkItem.id);
      if (index > -1) {
        tempCheckedValue.splice(index, 1);
      }
      setCheckValue(tempCheckedValue);
    } else {
      setCheckValue([...checkValue, checkItem.id]);
    }
  };

  // Select all images
  const selectAllImagesHandler = () => {
    setClickedSelectAll(true);
    setCheckValue(
      resImages?.length > 0 ? [...resImages?.map((i) => i.id)] : []
    );
  };

  // Un-Select all images
  const unSelectAllImagesHandler = () => {
    setClickedSelectAll(false);
    setCheckValue([]);
  };

  // For handle image delete modal
  const deleteResImages = () => {
    setDeleteModal(true);
  };

  // For handle image drag move
  const handleDragMove = (e) => {
    setDragCounter(dragCounter + 1);
  };

  // For handle image drag end
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      ref.current = true;
      const newImgArray = [...resImages];
      const activeIndex = newImgArray.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = newImgArray.findIndex((item) => item.id === over.id);
      const [draggedImage] = newImgArray.splice(activeIndex, 1);
      newImgArray.splice(overIndex, 0, draggedImage);
      setResImages(newImgArray);
    }
    if (dragCounter === 0) {
      const activeIndex = resImages.findIndex((item) => item.id === active?.id);
      const currentData = resImages && resImages[activeIndex];
      setImgZoom(true);
      setImgSrc(process.env.REACT_APP_FILE_BASE_URL + "/" + currentData?.image);
    }
    setDragCounter(0);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("form.restaurantImages")}</h2>
        </div>
        <div className="create_res_img_project_btn_row">
          <Link
            onClick={() => {
              setAddModal(true);
            }}
            className="comman_btn "
          >
            {t("common.add") + " " + t("common.image")}
          </Link>
          {resImages?.length !== 0 && (
            <button
              className={`comman_btn ${!ref.current && "disabled_btn"}`}
              onClick={() => saveDragResImgHandler()}
              disabled={!ref.current}
            >
              Save
            </button>
          )}
        </div>
        {loading || uploadLoading || ordering ? (
          <Loader />
        ) : resImages?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <>
            {/* <Button
              buttonClassName={`comman_btn mb-15 ${
                !ref.current && "disabled_btn"
              }`}
              className="create_from_bottom_btn"
              onClick={() => saveDragResImgHandler()}
              text={t("common.save")}
              disabled={!ref.current}
            /> */}

            <DndContext
              collisionDetection={closestCorners}
              onDragEnd={(e) => handleDragEnd(e)}
              onDragMove={(e) => handleDragMove(e)}
            >
              <SortableContext items={resImages}>
                <div className="oc-image-restaurant-gallery-row oc-image-restaurant-drag-drop-gallery-row">
                  {resImages.map((items, index) => (
                    <SortableImages
                      key={index}
                      images={items}
                      checkboxOnImgOnChangeHandler={
                        checkboxOnImgOnChangeHandler
                      }
                      checkValue={checkValue}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
            <div className="select-de-select-btn">
              <button
                className={`comman_btn select_all_clear_btn `}
                onClick={
                  (!clickedSelectAll && checkValue.length !== 0) ||
                  (checkValue.length === 0 && !clickedSelectAll)
                    ? selectAllImagesHandler
                    : unSelectAllImagesHandler
                }
              >
                {(!clickedSelectAll && checkValue.length !== 0) ||
                (checkValue.length === 0 && !clickedSelectAll)
                  ? t("form.select_all")
                  : t("form.de_select_all")}
              </button>

              {checkValue?.length > 0 && (
                <button
                  className="comman_btn select_delete_btn"
                  onClick={() => deleteResImages(checkValue)}
                >
                  {t("common.delete")}
                </button>
              )}
            </div>
          </>
        )}
        <DeleteResImgModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleClose={handleDeleteCloseHandler}
          checkValue={checkValue}
          setCheckValue={setCheckValue}
        />
        <ImageModal
          addModal={addModal}
          setAddModal={setAddModal}
          imgErr={imgErr}
          setImgErr={setImgErr}
          uploadLoading={uploadLoading}
          setUploadLoading={setUploadLoading}
        />
        <div
          onClick={() => setImgZoom(false)}
          className={`oc-table-img-modal-bg ${
            imgZoom && "oc-table-img-modal-open"
          }`}
        ></div>
        <div
          className={`oc-table-img-modal ${
            imgZoom && "oc-table-img-modal-open"
          }`}
        >
          <img src={imgSrc} alt="table img" />
        </div>
      </div>
    </>
  );
};

export default RestaurantImages;

import React from "react";
import faviconWhite from "../../images/onecitylogo/FaviconWhite.png";

const ForgotPasswordCheckMail = () => {
  return (
    <section className="login_section">
      <div className="login_content">
        <div className="login_top_content">
          {/* <h3>Welcome Back !</h3>
          <p>Sign in to One City Centre</p> */}
        </div>
        <div className="login_input_logo_content">
          <div className="login_logo">
            <img src={faviconWhite} alt="company_logo" />
          </div>
          <h2 className="zu_confirm_box_heading">Check your email</h2>
          <p className="zu_confirm_box_paragraph">
            To reset your password, tap the button in the email we sent to you.
          </p>
          <div style={{ textAlign: "center" }}>Open email app</div>
        </div>
      </div>
    </section>
    // <div className="zu_confirm_box_page">
    //   <div className="zu_confirm_box">
    //     <div className="zu_confirm_box_content">
    //       <Link to={routes.login} className="zu_confirm_box_logo">
    //         <img src={faviconWhite} alt="company_logo" />
    //       </Link>
    //       <h2 className="zu_confirm_box_heading">Check your email</h2>
    //       <p className="zu_confirm_box_paragraph">
    //         To reset your password, tap the button in the email we sent to you.
    //       </p>
    //       <div style={{ textAlign: "center" }}>Open email app</div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ForgotPasswordCheckMail;

import React, { useState } from "react";
import Button from "../../component/Common/Button";
import { checkIcon, crossIcon, deleteIcon, editIcon } from "../../icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTag, updateTag } from "../../store/slice/tagSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddTagModal from "./AddTagModal";
import DeleteTagModal from "./DeleteTagModal";
import Loader from "../../component/Common/Loader";
import { defaultTimeZone } from "../../helpers/commonFunction";
import { useRef } from "react";
import { useParams } from "react-router";
import Pagination from "../../component/Pagination/Pagination";
import { toast } from "react-toastify";

const Tag = () => {
  const dispatch = useDispatch();
  const ref = useRef(false);
  const params = useParams();
  const { page_number } = params;
  const { t } = useTranslation("common");

  const [tagForm, setTagForm] = useState(false);
  const [tagFormEdit, setTagFormEdit] = useState(false);
  const [tagName, setTagName] = useState("");
  const [tagNameErr, setTagNameErr] = useState("");
  const [tagId, setTagId] = useState("");
  const [tagDeleteModal, setTagDeleteModal] = useState(false);
  const [tagDeleteId, setTagDeleteId] = useState("");
  const [pageN, setPageN] = useState(page_number - 1);

  const tagList = useSelector((state) => state.tag.tagList?.data);
  const tagCount = useSelector((state) => state.tag.tagList?.count);
  const loading = useSelector((state) => state.tag.loader);

  useEffect(() => {
    if (ref.current) {
      dispatch(getTag({ start: (page_number - 1) * 10, limit: 10 }));
    }
  }, [pageN]);

  useEffect(() => {
    dispatch(getTag({ start: (page_number - 1) * 10, limit: 10 }));
    ref.current = true;
  }, []);

  const addTagFormToggle = () => {
    setTagForm(true);
  };

  const handleClose = () => {
    setTagForm(false);
    setTagId("");
    setTagFormEdit(false);
    setTagName("");
    setTagNameErr("");
  };

  const editTagHandler = (item) => {
    setTagForm(true);
    setTagFormEdit(true);
    setTagName(item.tag_name);
    setTagId(item.id);
    setTagNameErr("");
  };

  const deleteTagHandler = (id) => {
    setTagDeleteModal(true);
    setTagDeleteId(id);
  };

  const handleCloseHandler = () => {
    setTagDeleteModal(false);
    setTagDeleteId("");
  };

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageN(page_number);
    }
  };

  const activeTagHandler = (item) => {
    const data = {
      active_status: item.active_status === "1" ? "0" : "1",
    };
    dispatch(
      updateTag({
        data,
        id: item.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            toast.success(
              item.active_status === "0"
                ? t("common.tag") + " " + t("toast.available_msg")
                : t("common.tag") + " " + t("toast.unavailable_msg"),
              {
                autoClose: 2000,
              }
            );
            dispatch(getTag({ start: (page_number - 1) * 10, limit: 10 }));
          }
        },
      })
    );
  };

  return (
    <>
      <div className="project_edit_main_content">
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("common.tag")}</h2>
          <div className="creat_edit_project_btn_row">
            <Button
              className="project_submit_bottom_btn center_back_btn padding-0"
              buttonClassName="comman_btn"
              onClick={addTagFormToggle}
              text={t("common.create")}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : tagList?.length === 0 ? (
          <h2 className="ta_c c-grey">No results found</h2>
        ) : (
          <>
            <div className="custom_data_table_content">
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    <th className="custom_data_table_heading">
                      {t("table.name")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.is_available")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.createdAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.updatedAt")}
                    </th>
                    <th className="custom_data_table_heading">
                      {t("table.action")}
                    </th>
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {tagList?.map((item, index) => {
                    return (
                      <tr className="custom_data_table_row" key={item.id}>
                        <td className="custom_data_table_item table_item">
                          {item.tag_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.active_status === "1" ? checkIcon : crossIcon}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.created_at &&
                            moment
                              .utc(item.created_at, "YYYYMMDD HH:mm:ss")
                              .clone()
                              .tz(defaultTimeZone)
                              .format("DD/MM/YYYY, hh:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.updated_at
                            ? moment
                                .utc(item.updated_at, "YYYYMMDD HH:mm:ss")
                                .clone()
                                .tz(defaultTimeZone)
                                .format("DD/MM/YYYY, hh:mm A")
                            : "-"}
                        </td>
                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <button
                              className={`custom_data_table_view_edit_item_btn hide_compoenent_btn w-77 ${
                                item.active_status === "1" ? "red" : "green"
                              }`}
                              onClick={() => activeTagHandler(item)}
                            >
                              {item.active_status === "0"
                                ? t("table.available")
                                : t("table.un_available")}
                            </button>
                            <div className="tooltip">
                              <button
                                onClick={() => editTagHandler(item)}
                                className="custom_data_table_view_edit_item_btn"
                              >
                                {editIcon}
                              </button>
                              <span className="tooltiptext">
                                {t("common.edit")}
                              </span>
                            </div>
                            <div className="tooltip">
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => deleteTagHandler(item.id)}
                              >
                                {deleteIcon}
                              </button>
                              <span className="tooltiptext">
                                {t("common.delete")}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {tagList?.length > 0 && (
              <Pagination totalRecords={tagCount} onPageChange={onPageChange} />
            )}
          </>
        )}
        <DeleteTagModal
          tagDeleteModal={tagDeleteModal}
          setTagDeleteModal={setTagDeleteModal}
          tagDeleteId={tagDeleteId}
          setTagDeleteId={setTagDeleteId}
          handleClose={handleCloseHandler}
          pageNumber={page_number}
          totalCount={tagCount}
        />

        <AddTagModal
          tagForm={tagForm}
          tagName={tagName}
          tagNameErr={tagNameErr}
          setTagForm={setTagForm}
          setTagName={setTagName}
          setTagNameErr={setTagNameErr}
          tagFormEdit={tagFormEdit}
          setTagFormEdit={setTagFormEdit}
          tagId={tagId}
          setTagId={setTagId}
          handleClose={handleClose}
          pageNumber={page_number}
          totalCount={tagCount}
        />
      </div>
    </>
  );
};

export default Tag;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

//loginUser
// const language = localStorage.getItem("language") || "en";
export const loginUser = createAsyncThunk(
  "loginUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/onecity-restaurant/restaurant/login",
        data
        // {
        //   headers: {
        //     lang: language,
        //   },
        // }
      );
      // if (response.data.data.app_type !== 2) {
      //   console.log("not admin");

      //   notify("You are not authorized !");
      //   throw new Error("You are not authorized !");
      // }
      if (response.status === 200) {
        notify("Welcome Back", "success");
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const forgotPasswordAdmin = createAsyncThunk(
  "forgotPasswordAdmin",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/onecity-restaurant/restaurant/forgot-password",
        data.data
      );
      if (response.status === 200) {
        notify("Please check your emails for next steps", "success");
        data.navigate(routes.forgotpasswordcheckyouremail);
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const confirmPasswordAdmin = createAsyncThunk(
  "confirmPasswordAdmin",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/onecity-restaurant/restaurant/confirm-password",
        data.data,
        {
          headers: { Authorization: `bearer ${data.token}` },
        }
      );
      if (response.status === 200) {
        notify("Your password has been reset", "success");
        data.navigate(routes.login);
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/onecity-restaurant/restaurant/reset-password",
        data,
        authHeaders()
      );
      if (response.status === 200) {
        notify("Your password has been reset", "success");
        // data.navigate(routes.login);
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get User List
export const getRestaurantDetail = createAsyncThunk(
  "getRestaurantDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity-restaurant/restaurant`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

// // User Detail By Id
// export const getUserDetail = createAsyncThunk(
//   "getUserDetail",
//   async (data, thunkAPI) => {
//     try {
//       const response = await Axios.get(
//         `/onecity/users/get-user-by-id/${data.id}`,
//         authHeaders()
//       );
//       return response.data;
//     } catch (error) {
//       toast.error(error.response.data.message, {
//         autoClose: 3000,
//       });
//       return error.response.data.message;
//     }
//   }
// );

// Update User
export const updateRestaurant = createAsyncThunk(
  "updateRestaurant",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity-restaurant/restaurant/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

//createSlice
const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  userList: [],
  restaurantDetail: "",
  updateResUser: "",
  resetUserPassword: "",
  loader: false,
  error: "",
  user: initialUser,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },

  extraReducers: (builder) => {
    //loginUser
    builder.addCase(loginUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getUsers
    builder.addCase(getRestaurantDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRestaurantDetail.fulfilled, (state, action) => {
      state.loader = false;
      state.restaurantDetail = action.payload;
    });
    builder.addCase(getRestaurantDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // //getUserDetail
    // builder.addCase(getUserDetail.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(getUserDetail.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.userDetail = action.payload;
    // });
    // builder.addCase(getUserDetail.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // //updateUser
    // builder.addCase(updateUser.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(updateUser.fulfilled, (state, action) => {
    //   state.loader = false;
    //   state.updateUser = action.payload;
    // });
    // builder.addCase(updateUser.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;

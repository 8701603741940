import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import { eyeIcon, hideEyeIcon } from "../../icons";
import Button from "../../component/Common/Button";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/slice/userSlice";

const ChangePassword = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialFValues = {
    current_password: "",
    currentPasswordType: "password",
    current_password_err: "",
    new_password: "",
    newPasswordType: "password",
    new_password_err: "",
    confirm_password: "",
    confirmPasswordType: "password",
    confirm_password_err: "",
  };

  const validatePassword = () => {
    let isValid = false;

    if (!values.current_password) {
      setValues({
        ...values,
        current_password_err: "Current password is required!",
      });
    } else if (!values.new_password) {
      setValues({
        ...values,
        new_password_err: "New password is required!",
      });
    } else if (!values.confirm_password) {
      setValues({
        ...values,
        confirm_password_err: "Confirm Password is required!",
      });
    } else if (values.confirm_password !== values.new_password) {
      setValues({
        ...values,
        confirm_password_err: "Password & Confirm Password should be same!",
      });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const validate = (fieldValues = values) => {
    return validatePassword();
  };

  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    initialFValues,
    validate,
    true
  );

  const currentPasswordHandler = (e) => {
    setValues({
      ...values,
      current_password: e.target.value,
      current_password_err: "",
    });
  };

  const newPasswordHandler = (e) => {
    setValues({
      ...values,
      new_password: e.target.value,
      new_password_err: "",
    });
  };

  const confirmPasswordHandler = (e) => {
    setValues({
      ...values,
      confirm_password: e.target.value,
      confirm_password_err: "",
    });
  };

  const currentPasswordEyeIconHandler = () => {
    setValues({
      ...values,
      currentPasswordType:
        values.currentPasswordType === "password" ? "text" : "password",
    });
  };

  const newPasswordEyeIconHandler = () => {
    setValues({
      ...values,
      newPasswordType:
        values.newPasswordType === "password" ? "text" : "password",
    });
  };

  const confirmPasswordEyeIconHandler = () => {
    setValues({
      ...values,
      confirmPasswordType:
        values.confirmPasswordType === "password" ? "text" : "password",
    });
  };

  const updatePasswordHandler = () => {
    if (validatePassword()) {
      const data = {
        old_password: values.current_password,
        new_password: values.new_password,
      };
      dispatch(resetPassword(data));
      setValues({
        current_password: "",
        new_password: "",
        confirm_password: "",
      });
    }
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn announcement_page-container">
        <h2 className="mobile_configuration_heading">
          {t("table.reset_password")}
        </h2>

        <button
          className="comman_btn ml-auto secondary_btn"
          onClick={() => navigate(-1)}
        >
          {t("common.back")}
        </button>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="login_input_row m-0 null"
            type={values.currentPasswordType}
            placeholder={t("form.currentPasswordPlace")}
            label={t("form.currentPassword")}
            id="current_password"
            name="current_password"
            value={values.current_password}
            errorClassName="err_text"
            onChange={currentPasswordHandler}
            eyeIcon={
              values.currentPasswordType === "password" ? hideEyeIcon : eyeIcon
            }
            eyeIconHandler={currentPasswordEyeIconHandler}
            autoComplete="on"
            error={values.current_password_err || ""}
          />
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values.newPasswordType}
            placeholder={t("form.newPasswordPlace")}
            label={t("form.newPassword")}
            id="new_password"
            name="new_password"
            value={values.new_password}
            onChange={newPasswordHandler}
            eyeIcon={
              values.newPasswordType === "password" ? hideEyeIcon : eyeIcon
            }
            eyeIconHandler={newPasswordEyeIconHandler}
            autoComplete="on"
            error={values.new_password_err || ""}
          />
          <Input
            className="login_input_row m-0 null"
            errorClassName="err_text"
            type={values.confirmPasswordType}
            placeholder={t("form.confirmPasswordPlace")}
            label={t("form.confirmPassword")}
            id="confirm_password"
            name="confirm_password"
            value={values.confirm_password}
            onChange={confirmPasswordHandler}
            eyeIcon={
              values.confirmPasswordType === "password" ? hideEyeIcon : eyeIcon
            }
            eyeIconHandler={confirmPasswordEyeIconHandler}
            autoComplete="on"
            error={values.confirm_password_err || ""}
          />
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={updatePasswordHandler}
          text={t("common.update")}
        />
      </div>
    </>
  );
};

export default ChangePassword;

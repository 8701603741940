import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { categoryIcon, hashTagIcon, menuItemIcon } from "../../icons";
import Loader from "../../component/Common/Loader";
import { getCategory } from "../../store/slice/categorySlice";
import { getTag } from "../../store/slice/tagSlice";
import { getMenuItemList } from "../../store/slice/menuSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const categoryCount = useSelector(
    (state) => state.category.categoryList?.count
  );
  const categoryCountLoading = useSelector((state) => state.category.loader);
  const tagCount = useSelector((state) => state.tag.tagList?.count);
  const tagCountLoading = useSelector((state) => state.tag.loader);
  const menuItemCount = useSelector((state) => state.menuItem.menuItemList);
  const menuItemCountLoading = useSelector((state) => state.menuItem.loader);

  useEffect(() => {
    dispatch(getCategory({ start: 0, limit: 1000000000 }));
    dispatch(getTag({ start: 0, limit: 1000000000 }));
    dispatch(getMenuItemList({ start: 0, limit: 1000000000 }));
  }, []);

  return (
    <div className="project_edit_main_content">
      <>
        <div className="rolemanagement_project_btn_row_flex">
          <h2 className="project_subheading">{t("dashboard.head")}</h2>
        </div>
        {categoryCountLoading || tagCountLoading || menuItemCountLoading ? (
          <Loader />
        ) : (
          <div className="dashboard_top_column_row">
            <div className="dashboard_top_column pd_10">
              <div className="dashboard_top_column_main_icon">
                {menuItemIcon}
              </div>
              <div className="dashboard_top_column_left_content">
                <h2 className="dashboard_top_column_left_count_text">
                  {menuItemCount && menuItemCount.length > 0
                    ? menuItemCount.length
                    : "0"}
                </h2>
                <p className="dashboard_top_column_left_peragraph_text">
                  {t("dashboard.menu_items")}
                </p>
              </div>
            </div>

            <div className="dashboard_top_column pd_10">
              <div className="dashboard_top_column_main_icon">
                {categoryIcon}
              </div>
              <div className="dashboard_top_column_left_content">
                <h2 className="dashboard_top_column_left_count_text">
                  {categoryCount && categoryCount > 0 ? categoryCount : "0"}
                </h2>
                <p className="dashboard_top_column_left_peragraph_text">
                  {t("dashboard.categories")}
                </p>
              </div>
            </div>

            <div className="dashboard_top_column pd_10">
              <div className="dashboard_top_column_main_icon">
                {hashTagIcon}
              </div>
              <div className="dashboard_top_column_left_content">
                <h2 className="dashboard_top_column_left_count_text">
                  {tagCount && tagCount > 0 ? tagCount : "0"}
                </h2>
                <p className="dashboard_top_column_left_peragraph_text">
                  {t("common.tag")}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Dashboard;

export const addOnValidation = (addonList, setAddonErr, setTab) => {
  const hasEmptyValues = addonList.some(
    (addon) =>
      (addon.addon_name.trim() === "" && addon.price !== "") ||
      (addon.addon_name.trim() !== "" && addon.price === "")
  );

  if (hasEmptyValues) {
    setAddonErr("Please enter a valid addons!");
    setTab("addons");
    return false;
  } else {
    setAddonErr("");
    return true;
  }
};

export const variationValidation = (variationList, setVariationErr, setTab) => {
  let valid = false;
  for (let i = 0; i < variationList.length; i++) {
    const variation = variationList[i];
    if (
      variation.var_name === "" &&
      variation.item.some(
        (item) => item.item_name === "" && item.item_price === ""
      )
    ) {
      setVariationErr("");
      valid = true;
    } else if (
      variation.var_name === "" ||
      variation.item.some(
        (item) => item.item_name === "" || item.item_price === ""
      )
    ) {
      setVariationErr("Please enter a valid variations!");
      setTab("variations");
      return false;
    } else if (
      variation.var_name !== "" &&
      variation.item.some(
        (item) => item.item_name !== "" && item.item_price !== ""
      )
    ) {
      valid = true;
    }
  }
  return valid;
};

export const getVariationPriceOrMainPrice = (variationList) => {
  const list = [...variationList];
  let priceValue = 0;
  if (list.length !== 0) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i]?.item.length; j++) {
        if (list[i]?.item[j].is_default == 1) {
          priceValue = priceValue + +list[i]?.item[j].item_price;
        }
      }
    }
    return priceValue;
  }
};

export const customsValidation = (
  customOptionList,
  setCustomOptionErr,
  setTab
) => {
  let valid = false;
  for (let i = 0; i < customOptionList.length; i++) {
    const custom = customOptionList[i];
    if (
      custom.custom_name === "" &&
      custom.custom_item.some(
        (item) => item.item_name === "" && item.item_price === ""
      )
    ) {
      setCustomOptionErr("");
      valid = true;
    } else if (
      custom.custom_name === "" ||
      custom.custom_item.some(
        (item) => item.item_name === "" || item.item_price === ""
      )
    ) {
      setCustomOptionErr("Please enter a valid customs");
      setTab("customs");
      return false;
    } else if (
      custom.custom_name !== "" &&
      custom.custom_item.some(
        (item) => item.item_name !== "" && item.item_price !== ""
      )
    ) {
      valid = true;
    }
  }
  return valid;
};

export const createValidAddOnsList = (addonList) => {
  let newAddOn = [];
  addonList.some((addon) => {
    if (addon.addon_name.trim() !== "" && addon.price !== "") {
      newAddOn.push(addon);
    }
  });
  return newAddOn;
};

export const createValidVariationsList = (variationList) => {
  let newVariations = [];
  variationList.some((variation) => {
    if (
      variation.var_name.trim() !== "" &&
      variation.item.some(
        (item) => item.item_name !== "" && item.item_price !== ""
      )
    ) {
      newVariations.push(variation);
    }
  });
  return newVariations;
};

export const createValidCustomsList = (customOptionList) => {
  let newCustoms = [];
  customOptionList.some((custom) => {
    if (
      custom.custom_name.trim() !== "" &&
      custom.custom_item.some(
        (item) => item.item_name !== "" && item.item_price !== ""
      )
    ) {
      newCustoms.push(custom);
    }
  });
  return newCustoms;
};

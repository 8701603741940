import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import dashboardSlice from "./slice/dashboardSlice";
import fileUploadSlice from "./slice/fileUploadSlice";
import categorySlice from "./slice/categorySlice";
import restaurantImagesSlice from "./slice/restaurantImagesSlice";
import tagSlice from "./slice/tagSlice";
import menuImageSlice from "./slice/menuImageSlice";
import addOnSlice from "./slice/addOnSlice";
import createMenuItemSlice from "./slice/menuSlice";
import promoCodeSlice from "./slice/promoCodeSlice";
import cutlerySlice from "./slice/cutlerySlice";
import ordersSlice from "./slice/ordersSlice";
import feedbackSlice from "./slice/feedbackSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    fileUpload: fileUploadSlice,
    user: userReducer,
    category: categorySlice,
    resImg: restaurantImagesSlice,
    menuImg: menuImageSlice,
    menuItem: createMenuItemSlice,
    tag: tagSlice,
    addOn: addOnSlice,
    promoCode: promoCodeSlice,
    cutlery: cutlerySlice,
    order: ordersSlice,
    feedback: feedbackSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;

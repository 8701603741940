import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/Login/LoginPage";
import { routes } from "../src/constants";
import Category from "./pages/Category/Category";
import Profile from "./pages/Profile/Profile";
import ChangePassword from "./pages/Profile/ChangePassword";
import Tag from "./pages/Tag/Tag";
import Menu from "./pages/Menu/Menu";
import CreateMenu from "./pages/Menu/CreateMenu";
import EditMenu from "./pages/Menu/EditMenu";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ForgotPasswordCheckMail from "./pages/Login/ForgotPasswordCheckMail";
import ForgotPasswordPage from "./pages/Login/ForgotPasswordPage";
import CreateCategory from "./pages/Category/CreateCategory";
import RestaurantImages from "./pages/RestaurantImages/RestaurantImages";
import MenuImages from "./pages/MenuImages/MenuImages";
import PromoCode from "./pages/PromoCode/PromoCode";
import CreatePromoCode from "./pages/PromoCode/CreatePromoCode";
import Cutlery from "./pages/Cutlery/Cutlery";
import CreateCutlery from "./pages/Cutlery/CreateCutlery";
import Order from "./pages/Orders/Order";
import Feedback from "./pages/Feedback/Feedback";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.dashboard} />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: routes.forgotPassword, element: <ForgotPassword /> },
      {
        path: routes.forgotpasswordcheckyouremail,
        element: <ForgotPasswordCheckMail />,
      },
      {
        path: routes.forgotpasswordPage,
        element: <ForgotPasswordPage />,
      },
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <Layout /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.dashboard, element: <Dashboard /> },

      { path: routes.images, element: <RestaurantImages /> },
      // { path: routes.imagesByPage, element: <RestaurantImages /> },

      { path: routes.restaurant, element: <Profile /> },
      { path: routes.changePassword, element: <ChangePassword /> },

      { path: routes.category, element: <Category /> },
      { path: routes.categoryByPage, element: <Category /> },
      { path: routes.createCategory, element: <CreateCategory /> },
      { path: routes.editCategory, element: <CreateCategory /> },

      { path: routes.tag, element: <Tag /> },
      { path: routes.tagByPage, element: <Tag /> },

      { path: routes.menuItem, element: <Menu /> },
      { path: routes.createMenu, element: <CreateMenu /> },
      { path: routes.editMenu, element: <EditMenu /> },

      // { path: routes.menuImages, element: <MenuImages /> },
      // { path: routes.menuImagesByPage, element: <MenuImages /> },

      { path: routes.promoCode, element: <PromoCode /> },
      { path: routes.promoCodeByPage, element: <PromoCode /> },
      { path: routes.createPromoCode, element: <CreatePromoCode /> },
      { path: routes.editPromoCode, element: <CreatePromoCode /> },

      { path: routes.order, element: <Order /> },
      { path: routes.orderByPage, element: <Order /> },

      // { path: routes.cutlery, element: <Cutlery /> },
      // { path: routes.cutleryByPage, element: <Cutlery /> },
      // { path: routes.createCutlery, element: <CreateCutlery /> },
      // { path: routes.updateCutlery, element: <CreateCutlery /> },

      { path: routes.feedback, element: <Feedback /> },
      { path: routes.feedbackByPage, element: <Feedback /> },

      { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.login} /> : <Dashboard />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: "*", element: <LoginPage /> },
    ],
  },
];

export default getRoutes;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getOrderList = createAsyncThunk("getOrder", async (data) => {
  try {
    const response = await Axios.get(
      `/onecity-restaurant/order?user_id=${data.id || ""}&order_status=${
        data.order_status || ""
      }&order_date=${data.order_date || ""}&start=${data.start || 0}&limit=${
        data.limit || 10
      }`,
      authHeaders()
    );
    return { data: response.data, count: response.headers["x-total-count"] };
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

// Get ORDER by ID
export const getOrderById = createAsyncThunk(
  "getOrderById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/onecity-restaurant/order/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

// Update Menu
export const updateOrder = createAsyncThunk(
  "updateOrder",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/onecity-restaurant/order/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.menuItem}`);
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

//Update Order Item
export const updateOrderItem = createAsyncThunk(
  "updateOrderItem",
  async (data) => {
    try {
      const response = await Axios.put(
        `/onecity-restaurant/order/item/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orderList: [],
    orderDetail: {},
    updateOrder: "",
    updateOrderItems: "",
    loader: false,
    extraLoader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //get order list
    builder.addCase(getOrderList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getOrderList.fulfilled, (state, action) => {
      state.loader = false;
      state.orderList = action.payload;
    });
    builder.addCase(getOrderList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //get order list
    builder.addCase(getOrderById.pending, (state) => {
      state.extraLoader = true;
    });
    builder.addCase(getOrderById.fulfilled, (state, action) => {
      state.extraLoader = false;
      state.orderDetail = action.payload[0];
    });
    builder.addCase(getOrderById.rejected, (state, action) => {
      state.extraLoader = false;
      state.error = action.payload;
    });

    //Update order
    builder.addCase(updateOrder.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.loader = false;
      state.updateOrder = action.payload;
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //Update order Item
    builder.addCase(updateOrderItem.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateOrderItem.fulfilled, (state, action) => {
      state.loader = false;
      state.updateOrderItems = action.payload;
    });
    builder.addCase(updateOrderItem.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default ordersSlice.reducer;

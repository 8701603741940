import React, { useState } from "react";
import { useEffect } from "react";
import { clearLastTabData, getMenuItemById } from "../../store/slice/menuSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CreateMenu from "./CreateMenu";
import Loader from "../../component/Common/Loader";

const EditMenu = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const menuDetail = useSelector((state) => state.menuItem.menuDetail);
  const editLoading = useSelector((state) => state.menuItem.loader);

  useEffect(() => {
    dispatch(getMenuItemById({ id: id }));
  }, []);

  if (editLoading) {
    return <Loader />;
  }

  return <CreateMenu menuDetail={menuDetail} />;
};

export default EditMenu;

import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/component.css";
import { useLocation } from "react-router-dom";
import Logo from "../../images/onecitylogo/Logo-Black.png";
import sidebarSmallIcon from "../../images/onecitylogo/Logo.png";
import { routes } from "../../constants";
import {
  categoryIcon,
  dashboardIcon,
  feedbackIcon,
  hashTagIcon,
  menuIcon,
  orderIcon,
  promoCodeIcon,
  resImgIcon,
  resInfoIcon,
} from "../../icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState } from "react";

const Sidebar = ({ count, setCount }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  // const orderPageHandler = () => {
  //   navigate(`${routes.order}/page/1`);
  //   setCount(0);
  // };

  // useEffect(() => {
  //   if (pathname.match("order")) {
  //     setCount(0);
  //   }
  // }, [count]);

  return (
    <div className="sidebar_container">
      <Link
        to={routes.dashboard}
        className={`sidebar_logo ${
          pathname.match(/^.*dashboard.*$/) && "active"
        } `}
      >
        <div>
          <img
            src={Logo}
            alt="onecity_centre-logo"
            className="full_sidebar_logo"
          />
          <img
            src={sidebarSmallIcon}
            alt="onecity_centre-logo"
            className="small_sidebar_logo"
          />
        </div>
      </Link>
      <ul className="sidebar_nav">
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match("dashboard") && "active"
            } `}
            to={routes.dashboard}
          >
            {dashboardIcon}
            <span>{t("dashboard.head")}</span>
          </Link>
          <Link
            className={`sidebar_link ${
              pathname.match("restaurant") && "active"
            } `}
            to={routes.restaurant}
          >
            {resInfoIcon}
            <span>{t("table.restaurant_info")}</span>
          </Link>
          <Link
            className={`sidebar_link ${pathname.match("images") && "active"} `}
            to={`${routes.images}`}
          >
            {resImgIcon}
            <span>{t("form.restaurantImages")}</span>
          </Link>
          <Link
            className={`sidebar_link ${
              pathname.match("category") && "active"
            } `}
            to={`${routes.category}/page/1`}
          >
            {categoryIcon}
            <span>{t("form.category")}</span>
          </Link>
          <Link
            className={`sidebar_link ${pathname.match("tag") && "active"} `}
            to={`${routes.tag}/page/1`}
          >
            {hashTagIcon}
            <span>{t("table.tag")}</span>
          </Link>

          <Link
            className={`sidebar_link ${pathname.match("menu") && "active"} `}
            to={routes.menuItem}
          >
            {menuIcon}
            <span>{t("table.menu")}</span>
          </Link>
          <Link
            className={`sidebar_link ${pathname.match("order") && "active"}`}
            to={`${routes.order}/page/1`}
            // onClick={() => orderPageHandler()}
          >
            {count !== 0 && !pathname.match("order") && (
              <b className="sidebar_count">{count}</b>
            )}
            {orderIcon}
            <span>{t("table.orders")}</span>
          </Link>
          <Link
            className={`sidebar_link ${
              pathname.match("promo-code") && "active"
            } `}
            to={`${routes.promoCode}/page/1`}
          >
            {promoCodeIcon}
            <span>{t("table.promo_code")}</span>
          </Link>
          <Link
            className={`sidebar_link ${pathname.match("feedback") && "active"}`}
            to={`${routes.feedback}/page/1`}
          >
            {feedbackIcon}
            <span>{t("feedback.head")}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
